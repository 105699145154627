<template>
<!--  <teleport to="body">-->
    <div v-show="visible" class="p-dialog-mask">
      <div class="p-dialog-wrapper" :style="style">
        <div class="p-dialog">
          <div class="p-dialog-header">
            <slot name="header">
              <span class="p-dialog-title">{{header}}</span>
            </slot>
            <div class="p-dialog-header-icons" v-show="showCloseButton">
              <button class="p-dialog-header-icon p-dialog-header-close p-link" @click="close" type="button" tabindex="-1" v-ripple>
                <span class="p-dialog-header-close-icon pi pi-times"></span>
              </button>
            </div>
          </div>
          <div class="p-dialog-content">
            <slot></slot>
          </div>
          <div class="p-dialog-footer">
            <slot name="footer">{{footer}}</slot>
          </div>
<!--          <div class="dialog-bottom-space"></div>-->
        </div>
      </div>
    </div>
<!--  </teleport>-->
</template>

<script>
export default {
    emits: ['close'],
    props: {
        header: null,
        footer: null,
        visible: Boolean,
        style: Object,
        showCloseButton: {
          type: Boolean,
          default: true
        }
    },
    methods: {
      close() {
        this.$emit('close')
      }
    }
}
</script>

<style lang="scss" scoped>
.p-dialog-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  //width: 100%;
  //height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  transition-property: background-color;
  z-index: 1000;
  pointer-events: auto;
  overflow-y: auto;
  //background-color: red;
  //min-height: 100vh;
  //padding-bottom: 2120px;
}

.p-dialog-mask.p-component-overlay {
  //pointer-events: auto;
  pointer-events: auto;
}

.p-dialog-wrapper {
  //position: absolute;
  //padding: 20px 0;
  //top: 0;
  //bottom: 2vh;
  //box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
  //margin-top: 20px;
  //margin-bottom: 20px;
  //margin-bottom: 100px;
  //bottom: 4vh;
  //display: flex;
  //flex-direction: column;
  pointer-events: auto;
  //margin: 20px auto;
  //max-height: 98%;
  //transform: scale(1);
  z-index: 1001;
  //overflow: hidden;
  .p-dialog {
    margin: 25px 0 95px 0;
    //pointer-events: auto;
    //box-shadow: none;
  }
}

.dialog-bottom-space {
  //height: 20px;
  //background-color: var(--primary-color);
}

.p-dialog-content {
  //overflow-y: visible;
}

.p-dialog-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    padding: 15px 1.5rem;
}

.p-dialog-footer {
  flex-shrink: 0;
  //margin-top: -15px;
  //margin-top: 0px;
  //border-top: 1px solid pink;
}

//.p-dialog .p-dialog-header-icons {
//    display: flex;
//    align-items: center;
//}

.p-dialog .p-dialog-header-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    padding: 20px;
}

/* Fluid */
.p-fluid .p-dialog-footer .p-button {
    width: auto;
}


///* Animation */
///* Center */
//.p-dialog-enter-active {
//  transition: all 150ms cubic-bezier(0, 0, 0.2, 1);
//}
//.p-dialog-leave-active {
//  transition: all 150ms cubic-bezier(0.4, 0.0, 0.2, 1);
//}
//.p-dialog-enter-from,
//.p-dialog-leave-to {
//  opacity: 0;
//  transform: scale(0.7);
//}
//
//.p-dialog-mask.p-dialog-mask-leave {
//  background-color: transparent;
//}
//
///* Top, Bottom, Left, Right, Top* and Bottom* */
//.p-dialog-top .p-dialog,
//.p-dialog-bottom .p-dialog,
//.p-dialog-left .p-dialog,
//.p-dialog-right .p-dialog,
//.p-dialog-topleft .p-dialog,
//.p-dialog-topright .p-dialog,
//.p-dialog-bottomleft .p-dialog,
//.p-dialog-bottomright .p-dialog {
//  margin: .75rem;
//  transform: translate3d(0px, 0px, 0px);
//}
//.p-dialog-top .p-dialog-enter-active,
//.p-dialog-top .p-dialog-leave-active,
//.p-dialog-bottom .p-dialog-enter-active,
//.p-dialog-bottom .p-dialog-leave-active,
//.p-dialog-left .p-dialog-enter-active,
//.p-dialog-left .p-dialog-leave-active,
//.p-dialog-right .p-dialog-enter-active,
//.p-dialog-right .p-dialog-leave-active,
//.p-dialog-topleft .p-dialog-enter-active,
//.p-dialog-topleft .p-dialog-leave-active,
//.p-dialog-topright .p-dialog-enter-active,
//.p-dialog-topright .p-dialog-leave-active,
//.p-dialog-bottomleft .p-dialog-enter-active,
//.p-dialog-bottomleft .p-dialog-leave-active,
//.p-dialog-bottomright .p-dialog-enter-active,
//.p-dialog-bottomright .p-dialog-leave-active {
//  transition: all .3s ease-out;
//}
//.p-dialog-top .p-dialog-enter-from,
//.p-dialog-top .p-dialog-leave-to {
//  transform: translate3d(0px, -100%, 0px);
//}
//.p-dialog-bottom .p-dialog-enter-from,
//.p-dialog-bottom .p-dialog-leave-to {
//  transform: translate3d(0px, 100%, 0px);
//}
//.p-dialog-left .p-dialog-enter-from,
//.p-dialog-left .p-dialog-leave-to,
//.p-dialog-topleft .p-dialog-enter-from,
//.p-dialog-topleft .p-dialog-leave-to,
//.p-dialog-bottomleft .p-dialog-enter-from,
//.p-dialog-bottomleft .p-dialog-leave-to {
//  transform: translate3d(-100%, 0px, 0px);
//}
//.p-dialog-right .p-dialog-enter-from,
//.p-dialog-right .p-dialog-leave-to,
//.p-dialog-topright .p-dialog-enter-from,
//.p-dialog-topright .p-dialog-leave-to,
//.p-dialog-bottomright .p-dialog-enter-from,
//.p-dialog-bottomright .p-dialog-leave-to {
//  transform: translate3d(100%, 0px, 0px);
//}
//
///* Maximize */
//.p-dialog-maximized {
//  -webkit-transition: none;
//  transition: none;
//  transform: none;
//  width: 100vw !important;
//  max-height: 100%;
//  height: 100%;
//}
//.p-dialog-maximized .p-dialog-content {
//  flex-grow: 1;
//}

</style>
