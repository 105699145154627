<template>
  <Modal :style="computedWindowSize"
         :visible="visible"
         class="p-fluid"
         @close="close">
<!--    <template v-slot:header>-->
<!--      <div class="p-dialog-title">Appointment booking</div>-->
<!--    </template>-->

    <div class="p-formgrid p-grid p-pt-3">
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12" style="text-align: center">
        <i class="pi pi-check-circle green-color" style="font-size: 100px"></i>
        <h3>{{ $t('orderCreatedThankYouText') }}</h3>
        <h5 v-if="orderStartDate">{{ $t('Booking time') }}: {{ formatDate(orderStartDate) }}</h5>
      </div>
    </div>

    <template #footer>
      <Button :label="$t('Close')" icon="pi pi-times" class="p-button-text" @click="close"/>
    </template>
  </Modal>
</template>
<script>
import formatMixins from "@/mixins/formatMixins";

export default {
  mixins: [ formatMixins ],
  emits: ['close'],
  name: 'OrderCreatedModal',
  props: {
    visible: Boolean,
    orderStartDate: [Number, null],
  },
  methods: {
    close() {
      this.$emit('close')
      // this.submitted = false
    },
  },
  computed: {
    computedWindowSize() {
      if (this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {position: 'relative', top: '-10vh', width: '90%', maxWidth: '400px', marginTop: '10px'}
      } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {position: 'relative', top: '-10vh', width: '82%', maxWidth: '400px', marginTop: '25px'}
      } else {
        return {position: 'relative', top: '-10vh', width: '87%', maxWidth: '400px', marginTop: '50px'}
      }
    }
  },

}
</script>