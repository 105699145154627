const constants = {
    userTypes: {
        individual: 1,
        legal_entity: 2
    },
    userRoles: {
        superAdmin: 1,
        admin: 3,
        moderator: 5,
        accountant: 7,
        customer: 9,
        employeeWorkshopMaster: 11,
        employeeMaster: 13,
        spyEye: 20,
    },
}

export default constants
