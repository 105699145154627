<template>
  <div class="spinner-overlay" :class="[{'small-spinner':isSmall}, {'auto-height':overlayAutoHeight}]">
    <ProgressSpinner :style="spinnerStyle"/>
  </div>
</template>

<script>
export default {
  name: "Spinner",
  props: {
    isSmall: {
      type: Boolean,
      default: false,
    },
    spinnerStyle: {
      type: String,
      default: ''
    },
    overlayAutoHeight: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.spinner-overlay {
  //position: fixed;
  //top: 0;
  //left: 0;
  width: 100%;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  //margin: 0 auto;
  //background-color: rgba(0, 0, 0, 0.4);
  //z-index: 10000;
}

.small-spinner {
  width: auto;
  height: auto;
  margin: 0 auto;
}

//.auto-height {
//  height: auto;
//}
</style>
