<template>
  <div class="p-float-label p-input-icon-right">
    <i class="ti-comment-alt"/>
    <Textarea @input="onInput"
              :disabled="disabled"
              :class="{
                'p-invalid' : submitted && ((modelValue && modelValue.length > maxChars) || (required && !modelValue?.length)),
                'textarea-vertical-resize': textareaVerticalResize
              }"
              :id="id"
              :modelValue="modelValue"
              :rows="rows"
              :autoResize="autoResize"
              :autocomplete="autocomplete"/>
    <label :for="id">{{ label }}<span v-show="required" class="warning-color">*</span> <span :class="{'warning-color': modelValue?.length > maxChars}"> {{ modelValue?.length || 0 }}/{{ maxChars }}</span></label>
  </div>
  <small class="p-invalid" v-if="required && submitted && !modelValue?.length">{{ $t('Required field') }}</small>
</template>

<script>
export default {
  name: 'LimitedCharsTextarea',
  emits: ['update:modelValue'],
  props: {
    required: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: String,
      default: 'off'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    submitted: {
      type: Boolean,
      default: false
    },
    modelValue: String,
    maxChars: {
      type: Number,
      default: 500
    },
    id: String,
    rows: {
      type: Number,
      default: 3
    },
    autoResize: {
      type: Boolean,
      default: true
    },
    label: String,
    className: {
      type: String,
      default: ''
    },
    textareaVerticalResize: {
      type: Boolean,
      default: true
    },
  },
  // data() {
  //   return {
  //     text: ''
  //   }
  // },
  methods: {
    onInput(event) {
      const trimmedValue = event.target.value?.trim() ?? ''
      this.$emit('update:modelValue', trimmedValue);
    },
  }
}
</script>

<style scoped>
</style>