<template>
  <transition name="p-contextmenusub" @enter="onEnter">
    <ul ref="container" :class="containerClass" role="menu" v-if="root ? true : parentActive">
      <template v-for="(item, i) of model.filter(m => m.id >= 1)" :key="item.label + i.toString()">
        <li role="none" :class="getItemClass(item)" :style="item.style" v-if="visible(item) && !item.separator"
            @mouseenter="onItemMouseEnter($event, item)">
          <a :href="item.url" :class="getLinkClass(item)" :target="item.target" @click="onItemClick($event, item)" v-ripple
             :aria-haspopup="item.items != null" :aria-expanded="item === activeItem" role="menuitem" :tabindex="item.disabled ? null : '0'">
            <span :class="['p-menuitem-icon item-status', item.color]"></span>
            <span class="p-menuitem-text">{{item.label[$i18n.locale]}}</span>
            <span class="p-submenu-icon pi pi-angle-right" v-if="item.items"></span>
          </a>
          <sub-menu :model="item.items" v-if="visible(item) && item.items" :key="item.label + '_sub_'"
                    @leaf-click="onLeafClick()" :parentActive="item === activeItem" />
        </li>
        <li :class="['p-menu-separator', item.class]" :style="item.style" v-if="visible(item) && item.separator" :key="'separator' + i.toString()" role="separator"></li>
      </template>

      <template v-if="customerHaveStatusesHistory" >
        <hr class="p-m-2">
        <template v-for="(item, i) of model.filter(m => m.id === 0)" :key="item.label + i.toString()">
          <li role="none" :class="getItemClass(item)" :style="item.style" v-if="visible(item) && !item.separator"
              @mouseenter="onItemMouseEnter($event, item)">
            <a :href="item.url" :class="getLinkClass(item)" :target="item.target" @click="onItemClick($event, item)" v-ripple
               :aria-haspopup="item.items != null" :aria-expanded="item === activeItem" role="menuitem" :tabindex="item.disabled ? null : '0'">
              <span :class="['p-menuitem-icon item-status', item.color]"></span>
              <span class="p-menuitem-text">{{item.label[$i18n.locale]}}</span>
              <span class="p-submenu-icon pi pi-angle-right" v-if="item.items"></span>
            </a>
            <sub-menu :model="item.items" v-if="visible(item) && item.items" :key="item.label + '_sub_'"
                      @leaf-click="onLeafClick()" :parentActive="item === activeItem" />
          </li>
          <li :class="['p-menu-separator', item.class]" :style="item.style" v-if="visible(item) && item.separator" :key="'separator' + i.toString()" role="separator"></li>
        </template>
        <hr class="p-m-2">
        <template v-for="(item, i) of model.filter(m => m.id === null)" :key="item.label + i.toString()">
          <li role="none" :class="getItemClass(item)" :style="item.style" v-if="visible(item) && !item.separator"
              @mouseenter="onItemMouseEnter($event, item)">
            <a :href="item.url" :class="getLinkClass(item)" :target="item.target" @click="onItemClick($event, item)" v-ripple
               :aria-haspopup="item.items != null" :aria-expanded="item === activeItem" role="menuitem" :tabindex="item.disabled ? null : '0'">
              <span class="p-menuitem-text">{{item.label[$i18n.locale]}}</span>
              <span class="p-submenu-icon pi pi-angle-right" v-if="item.items"></span>
            </a>
            <sub-menu :model="item.items" v-if="visible(item) && item.items" :key="item.label + '_sub_'"
                      @leaf-click="onLeafClick()" :parentActive="item === activeItem" />
          </li>
          <li :class="['p-menu-separator', item.class]" :style="item.style" v-if="visible(item) && item.separator" :key="'separator' + i.toString()" role="separator"></li>
        </template>
      </template>
    </ul>
  </transition>
</template>

<script>
import {DomHandler} from 'primevue/utils';
import Ripple from 'primevue/ripple';
export default {
  emits: ['leaf-click'],
  name: 'sub-menu',
  props: {
    customerHaveStatusesHistory: {
      type: Boolean,
      default: false,
    },
    model: {
      type: Array,
      default: null
    },
    root: {
      type: Boolean,
      default: false
    },
    parentActive: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    parentActive(newValue) {
      if (!newValue) {
        this.activeItem = null;
      }
    }
  },
  data() {
    return {
      activeItem: null
    }
  },
  methods: {
    onItemMouseEnter(event, item) {
      if (item.disabled) {
        event.preventDefault();
        return;
      }
      this.activeItem = item;
    },
    onItemClick(event, item, navigate) {
      if (item.disabled) {
        event.preventDefault();
        return;
      }
      if (item.command) {
        item.command({
          originalEvent: event,
          item: item
        });
      }
      if (item.items) {
        if (this.activeItem && item === this.activeItem)
          this.activeItem = null;
        else
          this.activeItem = item;
      }
      if (!item.items) {
        this.onLeafClick(item);
      }
      if (item.to && navigate) {
        navigate(event);
      }
    },
    onLeafClick(item) {
      this.activeItem = null;
      this.$emit('leaf-click', item.id);
    },
    onEnter() {
      this.position();
    },
    position() {
      const parentItem = this.$refs.container.parentElement;
      const containerOffset = DomHandler.getOffset(this.$refs.container.parentElement)
      const viewport = DomHandler.getViewport();
      const sublistWidth = this.$refs.container.offsetParent ? this.$refs.container.offsetWidth : DomHandler.getHiddenElementOuterWidth(this.$refs.container);
      const itemOuterWidth = DomHandler.getOuterWidth(parentItem.children[0]);
      this.$refs.container.style.top = '0px';
      if ((parseInt(containerOffset.left, 10) + itemOuterWidth + sublistWidth) > (viewport.width - DomHandler.calculateScrollbarWidth())) {
        this.$refs.container.style.left = -1 * sublistWidth + 'px';
      }
      else {
        this.$refs.container.style.left = itemOuterWidth + 'px';
      }
    },
    getItemClass(item) {
      return [
        'p-menuitem', item.class, {
          'p-menuitem-active': this.activeItem === item
        }
      ]
    },
    getLinkClass(item) {
      return ['p-menuitem-link', {'p-disabled': item.disabled}];
    },
    visible(item) {
      return (typeof item.visible === 'function' ? item.visible() : item.visible !== false);
    }
  },
  computed: {
    containerClass() {
      return {'p-submenu-list': !this.root};
    }
  },
  directives: {
    'ripple': Ripple
  }
}
</script>
<style lang="scss" scoped>
.item-status {
  //display: flex;
  //justify-content: center;
  //align-items: center;
  //padding: 2px;
  height: 16px;
  width: 16px;
  border-radius: 50px;
  font-size: 0.9em;
}


.grey {
  background: #ccc;
}

.red {
  background: #ff6464;
}
.green {
  background: #60c666;
}

.yellow {
  background: #fff700;
}
//.states-title {
//  width: 100%;
//  //text-align: center;
//  padding: 10px 0 10px 16px;
//  font-weight: 500;
//  //background-color: #fafaf8
//  //border-top: 1px solid #333;
//  //border-bottom: 1px solid #333;
//}
</style>