export default [
    {
        num: 1,
        label: {
            en: 'New',
            et: 'Uus',
            ru: 'Новый',
        },
        color: 'green'
    },
    {
        num: 3,
        label: {
            en: 'Estimated',
            et: 'Hinnatud',
            ru: 'Оценено',
        },
        color: 'blue'
    },
    {
        num: 5,
        label: {
            en: 'Order created',
            et: 'Tellimus loodud',
            ru: 'Заказ создан',
        },
        color: 'dark-blue'
    },
    {
        num: 7,
        label: {
            en: 'Canceled',
            et: 'Tühistatud',
            ru: 'Отменено',
        },
        color: 'red'
    },
]


// export default {
//     en: [
//         {num: 1, label: 'New', color: 'green'},
//         {num: 3, label: 'Estimated', color: 'blue'},
//         {num: 5, label: 'Order created', color: 'dark-blue'},
//         {num: 7, label: 'Canceled', color: 'red'},
//     ],
//     et: [
//         {num: 1, label: 'Uus', color: 'green'},
//         {num: 3, label: 'Estimated', color: 'blue'},
//         {num: 5, label: 'Order created', color: 'dark-blue'},
//         {num: 7, label: 'Canceled', color: 'red'},
//     ],
//     ru: [
//         {num: 1, label: 'Новый', color: 'green'},
//         {num: 3, label: 'Estimated', color: 'blue'},
//         {num: 5, label: 'Order created', color: 'dark-blue'},
//         {num: 7, label: 'Canceled', color: 'red'},
//     ]
// }
