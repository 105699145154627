<template>
  <div class="p-d-flex p-ai-center search-input-wrapper">
    <div class="p-input-icon-right">
      <i v-if="modelValue?.length" class="pi pi-times pointer" @click="resetSearch"/>
      <InputText :disabled="disabled" @keyup.enter="searchItems" @input="onInput" class="search-input" :modelValue="modelValue" :placeholder="$t('Search') + '...'" />
    </div>
    <div>
<!--      <Button :disabled="isSearchBtnDisabled || disabled" icon="pi pi-search" class="search-input-btn" @click="searchItems"></Button>-->
      <Button :disabled="disabled" icon="pi pi-search" class="search-input-btn" @click="searchItems"></Button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchInput',
  emits: ['update:modelValue', 'show-spinner', 'search-items', 'change-processed-search-data'],
  props: {
    modelValue: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    changeUrl: {
      type: Boolean,
      default: true,
    },
    clearLastSearchingValue: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    clearLastSearchingValue(value) {
      if (value) {
        this.lastSearchingValue = null
      }
    }
  },
  data() {
    return {
      lastSearchingValue: null
    }
  },
  methods: {
    onInput(event) {
      let trimmedValue = event.target.value?.trim() ?? ''
      if (trimmedValue.length === 0) {
        trimmedValue = null
      }
      this.$emit('update:modelValue', trimmedValue);
    },
    searchItems() {
      // if (this.modelValue === this.lastSearchingValue || this.$route.query.search === this.modelValue) return
      if (this.$route.query.search === this.modelValue) return

      if (!this.changeUrl) {
        this.$emit('search-items');
        return false
      }

      const route = {
        route: this.$route.path,
        query: {
          ...this.$route.query,
          search: this.modelValue,
        }
      }

      if (!this.modelValue) {
        // this.$emit('update:modelValue', '');
        // this.$emit('show-spinner')
        // delete route.query.search
        this.resetSearch()
      } else {
        this.lastSearchingValue = this.modelValue
        this.$emit('change-processed-search-data', this.modelValue);
        this.$emit('show-spinner')
        this.$router.push(route)
      }
    },
    resetSearch() {
      this.$emit('change-processed-search-data', null);
      this.lastSearchingValue = null
      this.$emit('update:modelValue', null);
      if (!this.$route.query.search && this.changeUrl) return
      if (!this.changeUrl) {
        this.$emit('search-items');
        return false
      }

      const route = {
        route: this.$route.path,
        query: {
          ...this.$route.query,
          // search: null,
        }
      }
      delete route.query.search

      this.$emit('show-spinner')
      // this.isLoading = true
      this.$router.push(route)
      // this.getItems()
      // }
    },
  },
  computed: {
    isSearchBtnDisabled() {
      // console.log(!this.$route.query.search, !this.modelValue)
      return (!this.$route.query.search && !this.modelValue) ||
          (this.modelValue === this.lastSearchingValue) ||
          this.$route.query.search === this.modelValue
      // return false
    }
  }

}
</script>

<style scoped lang="scss">
.search-input {
  border-radius: 3px 0 0 3px;
  width: auto;
}
.search-input-btn {
  border-radius: 0 3px 3px 0;
}
</style>