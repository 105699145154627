<template>
  <div class="button-wrapper">
    <Button
        class="p-link language-selector"
        :class="{'language-selector--short':selectorIsShort || isSmall}"
        @click="toggleLanguageSelector">
      <div>
        <span class="flag--language-selector flag" :class="'flag-' + activeLanguage?.flagCode"></span>
      </div>
      <div v-if="!selectorIsShort && !isSmall">
        <span class="language-selector__active-lang">{{ activeLanguage?.label }}</span>
        <span >
          <i class="pi pi-angle-down language-selector__arrow" :class="{'language-selector__arrow--down':languageMenuActive}"></i>
        </span>
      </div>

    </Button>

    <transition name="p-connected-overlay">
      <ul v-if="notActiveLanguages?.length" :class="{'topbar-language-menu-visible': languageMenuActive}" class="topbar-language-menu">
        <li v-for="(language, index) of notActiveLanguages"
            :key="index">
          <button class="p-link" @click="changeLanguage(language.value)">
            <span class="flag--language-selector flag" :class="'flag-' + language.flagCode"></span>
            <span class="topbar-item-name">{{ language.label }}</span>
          </button>
        </li>
      </ul>
    </transition>
  </div>

</template>

<script>
import settings from "@/settings";
export default {
  name: 'LoginLanguageSelector',
  emits: ['languageMenubuttonClick'],
  props: {
    languageMenuActive: Boolean,
    mobileLayout: {
      type: Boolean,
      default: false
    },
    isSmall: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
      selectorIsShort: false,
    }
  ),
  mounted() {
    this.checkWindowSize()
    window.addEventListener('resize', this.checkWindowSize)
  },
  unmounted() {
    window.removeEventListener('resize', this.checkWindowSize)
  },
  methods: {
    checkWindowSize() {
      if (window.innerWidth <= 480) {
        this.selectorIsShort = true
      } else {
        this.selectorIsShort = false
      }
    },
    toggleLanguageSelector() {
      this.$emit('languageMenubuttonClick')
    },
    changeLanguage(value) {
      this.$i18n.locale = value
      localStorage.setItem('lang', value)
      this.toggleLanguageSelector()
    }
  },
  computed: {
    activeLanguage() {
      // const language = this.languages.filter(item => item.value === this.$i18n.locale)
      // return { ...language[0] }

      return settings.languages.find(item => item.value === this.$i18n.locale)
    },
    notActiveLanguages() {
      return settings.languages.filter(item => item.value !== this.$i18n.locale)
    },
    // selectorIsShort() {
    //   return this.$store.state.mobileLayout
    // },
  },
  // mounted() {
  //   const language = localStorage.getItem('lang')
  //   if (language) {
  //     console.log(language)
  //   //   this.$i18n.locale = this.languages[language]
  //   }
  // }
}
</script>

<style lang="scss" scoped>
.button-wrapper {
  position: relative;
  z-index: 10;
}

.language-selector {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  //margin-right: 10px;
  min-width: 120px;
  padding: 6px 4px !important;
  font-weight: 400 !important;

  @media screen and (max-width: 480px) {
    //margin-right: 0;
    min-width: auto;
  }

  .language-selector__arrow {
    //margin-left: -6px;
    position: relative;
    top: 2px;
    margin-left: 2px;
    font-size: 14px !important;
    transition: transform 0.2s ease;
    //@media screen and (max-width: 840px) {
    //  //display: none;
    //}
    //@media screen and (max-width: 740px) {
    //  //display: none;
    //  margin-left: -2px;
    //}
  }

  .language-selector__arrow--down {
    transform: rotate(180deg);
  }
}

.language-selector--short {
  min-width: auto;
}

.topbar-language-menu {
  min-width: 120px;
  //position: absolute;
  right: 0;
  top: 0;
  //transform: translateY(calc(50% + 4px));
  transform: translateY(44px);
}



</style>
