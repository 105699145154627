<template>
  <Button
      class="p-link language-selector"
      id="language-selector-button"
      @click="toggleLanguageSelector">
    <div>
      <span :class="'flag--language-selector flag flag-' + activeLanguage?.flagCode"></span>
    </div>
<!--    <div class="language-selector__active-lang">{{ activeLanguage.label }}</div>-->
<!--    <span>-->
<!--      <i class="pi pi-angle-down language-selector__arrow"></i>-->
<!--    </span>-->
  </Button>
  <transition name="p-connected-overlay">
    <ul v-if="notActiveLanguages?.length" :class="{'topbar-language-menu-visible': languageMenuActive}" class="topbar-language-menu">
      <li v-for="(language, index) of notActiveLanguages"
          :key="index">
        <button class="p-link" @click="changeLanguage(language.value, language?.flagCode)">
          <span :class="'flag--language-selector flag flag-' + language?.flagCode" class=""></span>
          <span class="topbar-item-name">{{ language?.label }}</span>
        </button>
      </li>
    </ul>
  </transition>
</template>

<script>
// import authService from '@/services/auth.service'
import httpClient from '@/services/http.services'
import httpMixins from "@/mixins/httpMixins";
import settings from "@/settings";

export default {
  mixins: [ httpMixins ],
  name: 'TopbarLanguageSelector',
  emits: ['language-menubutton-click', 'languageMenubuttonClick'],
  props: {
    language: String,
    languageMenuActive: Boolean,
  },
  // data: () => ({
  //   languages
  // }),
  methods: {
    async changeLanguage(value, flagCode, changeLocale = true) {
      try {
        const { status } = await httpClient.post('user/change-language', { language: value, flag_code: flagCode })
        if (status === 200) {
          localStorage.setItem('lang', value)

          if (changeLocale) {
            this.$i18n.locale = value
          }
        }
      } catch(err) {
        this.showError(err)
      }
    },
    toggleLanguageSelector() {
      this.$emit('language-menubutton-click')
    },
  },
  computed: {
    activeLanguage() {
      // const language = this.languages.filter(item => item.value === this.$i18n.locale)
      // return { ...language[0] }

      return settings.languages.find(item => item.value === this.$i18n.locale)
    },
    notActiveLanguages() {
      return settings.languages.filter(item => item.value !== this.$i18n.locale)
    }
  },
}
</script>

<style scoped lang="scss">
.language-selector {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 10px;
  min-width: 50px;
  padding: 6px 4px !important;
  font-weight: 400 !important;
  //text-align: left;
  @media screen and (max-width: 640px) {
    margin-right: 0;
  }

  .language-selector__active-lang {
    @media screen and (max-width: 840px) {
        //display: none;
    }
  }

  .language-selector__arrow {
    //margin-left: -6px;
    position: relative;
    margin-left: 2px;
    font-size: 14px !important;
    @media screen and (max-width: 840px) {
      //display: none;
    }
    @media screen and (max-width: 740px) {
      //display: none;
      margin-left: -2px;
    }
  }
}
//.languages-list {
//  //display: flex;
//  //flex-flow: column;
//  //justify-content: center;
//  //list-style: none;
//  border-radius: 0;
//}

</style>
