<template>
  <div class="price-request-form-body"></div>
</template>

<script>
export default {
  name: "EmptyPage"
}
</script>

<style scoped>

</style>