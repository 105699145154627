'use strict'
import httpClient from "@/services/http.services";
import settings from "@/settings";

const httpMixins = {
    data() {
        return {
            supplierSearchData: '',
            suppliersDataIsLoading: false,
            selectedSupplier: null,
            selectedSupplierId: null,
            suppliersData: null,
        }
    },
    // mounted() {
    //     console.log('httpMixins mounted')
    // },
    methods: {
        showError(err) {
            if (!err) return false
            // console.log(err.response?.data)
            const errorName = err.response?.data?.name ? err.response.data.name + ':' : ''
            const errorMessage = err.response?.data?.message ?? err ?? 'Unknown error'
            // Database Exception?

            if (this.$toast) {
                const isDatabaseException = errorName.includes('Database Exception')
                if (isDatabaseException) {
                    const numberOutOfRange = errorMessage.includes('Numeric value out of range')
                    if (numberOutOfRange) {
                        this.$toast.add({severity:'error', summary: this.$t('Error'), detail: 'Numeric value out of range', life: settings.toastLife})
                    } else {
                        this.$toast.add({severity:'error', summary: this.$t('Error'), detail: 'Database Exception', life: settings.toastLife})
                    }
                } else {
                    this.$toast.add({severity:'error', summary: `${errorName}`, detail: `${errorMessage}`, life: settings.toastLife})
                }
                // this.$toast.add({position: 'bottom-center',severity:'error', summary: `${errorName}`, detail: `${errorMessage}`, life: settings.toastLife})
            }
            console.log(`${errorName} ${errorMessage}`)
        },
        // showFinancialPeriodIsClosedError() {
        //     this.$toast.add({severity:'warn', detail: 'Financial period already closed', life: this.settings.toastLife});
        // },
        async getModalData() {
            try {
                const { status, data } = await httpClient('common/get-modal-data?expand=area')
                if (status === 200 && data) {
                    this.taxes = data.taxes ?? []
                    this.masters = data.masters ?? []
                    this.responsibles = data.responsibles ?? []
                    this.services = data.services ?? []
                    this.suppliers = data.suppliers ?? []
                    this.areas = data.areas ?? []
                    this.warehouseItems = data.warehouse_items ?? []
                    // this.warehouseItems = []
                }
            } catch(err) {
                // console.log(err)
                this.showError(err)
            }
        },
        async getNewOffersCount() {
            try {
                const { status, data } = await httpClient('offer/get-new-offers-count')
                if (status === 200 && data) {
                    // data.role = this.computedUserRole(data.role)
                    this.$store.commit('updateNewOffersCount', data)
                }
            } catch(err) {
                this.showError(err)
            }
        },
        async getMasters() {
            try {
                const { status, data } = await httpClient('user/get-masters')
                if (status === 200 && data) {
                    const masters = Array.isArray(data) ? data : []
                    if (masters.length) {
                        this.masters = [ ...data ].map(item => {
                            return {id: item.id, full_name: (item.first_name || '') + ' ' + (item.last_name || '')}
                        })
                    } else {
                        this.masters = []
                    }
                }
            } catch(err) {
                this.showError(err)
            }
        },
        async getTaxes() {
            try {
                const { data, status } = await httpClient('tax/get-taxes')
                if (status === 200 && data) {
                    this.taxes = Array.isArray(data) ? data : []
                    // this.defaultTaxId = data.filter(tax => tax.is_default === 1)[0] ? data.filter(tax => tax.is_default === 1)[0].id : null
                }
            } catch(err) {
                this.showError(err)
            }
        },
        async getServices() {
            try {
                const { data, status } = await httpClient('service/get-services')
                if (status === 200 && data) {
                    this.services = Array.isArray(data) ? data : []
                }
            } catch(err) {
                this.showError(err)
            }
        },
        //Uses in 'purchase invoices' component
        async getSuppliers() {
            try {
                const { data, status } = await httpClient('supplier/get-suppliers')
                if (status === 200 && data) {
                    this.suppliers = Array.isArray(data) ? data : []
                    // console.log(this.suppliers)
                }
            } catch(err) {
                this.showError(err)
            }
        },
        async getWarehouseCategories() {
            try {
                const { data, status } = await httpClient('warehouse/get-categories?sort=sorting')
                if (status === 200 && data) {
                    // this.warehouseCategories = Array.isArray(data) ? data : []
                    this.warehouseCategories = data
                }
            } catch(err) {
                this.showError(err)
            }
        },
        async getWarehouseItems() {
            try {
                const { data, status } = await httpClient('warehouse/get-items')
                if (status === 200 && data) {
                    this.warehouseItems = Array.isArray(data) ? data : []
                }
            } catch(err) {
                this.showError(err)
            }
        },
        async getAreas() {
            try {
                const { status, data } = await httpClient('area/get-areas')
                if (status === 200 && data) {
                    const areas = Array.isArray(data) ? data : []
                    if (areas.length) {
                        this.areas = areas.sort((a, b) => a.sorting - b.sorting)
                    } else {
                        this.areas = []
                    }
                }
            } catch(err) {
                this.showError(err)
            }
        },
        async getCashboxUsers() {
            try {
                const { status, data } = await httpClient('user/get-cashbox-users')
                if (status === 200 && data) {
                    this.cashboxUsers = data
                }
            } catch(err) {
                this.showError(err)
            }
        },
        async getEmployees() {
            try {
                const { status, data } = await httpClient('user/get-employees')
                if (status === 200 && data) {
                    this.employees = data
                }
            } catch(err) {
                this.showError(err)
            }
        },
        async getLinkUsers() {
            try {
                const { status, data } = await httpClient('user/get-link-users')
                if (status === 200 && data) {
                    this.linkUsers = data
                }
            } catch(err) {
                this.showError(err)
            }
        },
        async searchSuppliers(event) {
            let searchData = event.value
            // if (searchData) {
            this.suppliersDataIsLoading = true

            searchData = searchData.replace(' ','%20')

            try {
                const { status, data } = await httpClient('supplier/get-suppliers?search=' + searchData)
                if (status === 200 && data) {
                    this.suppliersData = data
                }
            } catch(err) {
                this.showError(err)
            } finally {
                this.suppliersDataIsLoading = false
            }
        },
        changeSupplier() {
            if (!this.selectedSupplier) {
                this.suppliersData = [...this.suppliers]
                this.$refs.selectedSupplierDropdown?.clearFilterValue()
            }
            this.selectedSupplierId = this.selectedSupplier ? +this.selectedSupplier.id : null
            const route = {
                route: this.$route.path,
                query: {
                    ...this.$route.query,
                    supplier: this.selectedSupplierId,
                    // page: 1,
                }
            }

            delete route.query.page

            if (!this.selectedSupplierId) {
                delete route.query.supplier
            }
            this.$router.push(route)
        },
        checkSupplierInArray() {
            if (this.$route.query.supplier) {
                this.selectedSupplierId = +this.$route.query.supplier
                const supplier = this.suppliersData?.find(supplier => supplier.id === +this.$route.query.supplier)
                if (supplier) {
                    this.selectedSupplier = supplier
                } else {
                    this.getUndefinedSupplier(+this.$route.query.supplier).then(supplier => {
                        if (supplier?.id) {
                            this.selectedSupplier = supplier
                            if (this.suppliersData) {
                                this.suppliersData.push(supplier)
                            } else {
                                this.suppliersData = []
                                this.suppliersData.push(supplier)
                            }
                        }
                    })
                }
            } else {
                this.selectedSupplierId = null
                this.selectedSupplier = null
            }
        },
        async getUndefinedSupplier(supplierId) {
            try {
                const { status, data } = await httpClient.post('supplier/get-supplier-by-id', { id: supplierId })
                if (status === 200 && data) {
                    return data
                }
            } catch(err) {
                this.showError(err)
            }
        },
    }
}

export default httpMixins
