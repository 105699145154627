import constants from '@/constants'

const superAdmin = constants.userRoles.superAdmin
const admin = constants.userRoles.admin
const moderator = constants.userRoles.moderator
const accountant = constants.userRoles.accountant
// const employeeWorkshopMaster = constants.userRoles.employeeWorkshopMaster
// const employeeMaster = constants.userRoles.employeeMaster

const WarehouseRouter = [
    {
        path: '/warehouse-items',
        name: 'WarehouseItems',
        meta: {
            auth: true,
            roles: [superAdmin, admin, accountant, moderator],
            title: 'Warehouse items'
        },
        component: () => import('@/pages/warehouse/WarehouseItems'),
    },
    {
        path: '/requests',
        name: 'Requests',
        meta: {
            auth: true,
            roles: [superAdmin, admin, accountant, moderator],
            title: 'Requests'
        },
        component: () => import('@/pages/warehouse/Requests'),
    },
    {
        path: '/returns',
        name: 'Returns',
        meta: {
            auth: true,
            roles: [superAdmin, admin, accountant, moderator],
            title: 'Returns'
        },
        component: () => import('@/pages/warehouse/Returns'),
    },
    {
        path: '/warehouse-requests',
        name: 'WarehouseRequests',
        meta: {
            auth: true,
            roles: [superAdmin, admin, accountant],
            title: 'Warehouse requests'
        },
        component: () => import('@/pages/warehouse/WarehouseRequests'),
    },
]

export default WarehouseRouter