<template>
	<footer class="layout-footer">
<!--		<span class="footer-text-left">© {{ getYear }} <b><a href="https://virtualgarage.app/" target="_blank">VirtualGarage.app</a></b>. {{ $t('All Rights Reserved') }}.</span>-->
		<span class="footer-text-left">©{{ getYear }} <b>CARVEX OÜ</b>. {{ $t('All Rights Reserved') }}.</span>
	</footer>
</template>

<script>
	export default {
		name: "AppFooter",
    computed: {
      getYear() {
        return new Date().getFullYear()
      }
    }
	}
</script>

<style scoped>

</style>
