<template>
  <Button :label="label" icon="ti-trash" class="p-button-raised p-button-danger"/>
</template>

<script>
export default {
  name: 'MultipleDeleteButton',
  props: {
    label: String
  }
}
</script>

<style scoped>

</style>