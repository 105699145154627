import constants from '@/constants'

const MessagesRouter = [
    {
        path: '/messages',
        name: 'Messages',
        meta: {
            auth: true,
            roles: [constants.userRoles.superAdmin, constants.userRoles.admin, constants.userRoles.moderator, constants.userRoles.accountant],
            title: 'Messages'
        },
        component: () => import('@/pages/messages/Messages'),
    },
]

export default MessagesRouter