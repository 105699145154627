<template>
  <span v-if="sort?.column !== columnName" class="p-sortable-column-icon pi pi-fw pi-sort-alt"></span>
  <span v-else>
    <span v-if="sort?.direction === '-'" class="p-sortable-column-icon pi pi-fw pi-sort-amount-down"></span>
    <span v-if="sort?.direction === ''" class="p-sortable-column-icon pi pi-fw pi-sort-amount-up-alt"></span>
  </span>
</template>

<script>
export default {
  name: 'ColumnSorting',
  props: {
    columnName: String,
    sort: Object
  }
}
</script>

<style scoped>

</style>
