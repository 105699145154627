'use strict'
const calculateMixins = {
    methods: {
        countOverdueDays(paymentDueDay) {
            const now = new Date()
            const nowYear = now.getFullYear()
            const nowMonth = now.getMonth()
            const nowDay = now.getDate()
            const today = new Date(nowYear, nowMonth, nowDay)

            paymentDueDay = new Date(paymentDueDay * 1000)
            const dateToPayYear = paymentDueDay.getFullYear()
            const dateToPayMonth = paymentDueDay.getMonth()
            const dateToPayDay = paymentDueDay.getDate()
            const dateToPay = new Date(dateToPayYear, dateToPayMonth, dateToPayDay)

            return ((+new Date(dateToPay) / 1000 / 3600 / 24) - (+new Date(today) / 1000 / 3600 / 24)).toFixed()
        },
        calculateDateWithCurrentTime(date) {
            const now = new Date()
            const year = date.getFullYear()
            const month = date.getMonth()
            const day = date.getDate()

            const hours = now.getHours()
            const minutes = now.getMinutes()
            const seconds = now.getSeconds()
            const ms = now.getMilliseconds()

            return new Date(year, month, day, hours, minutes, seconds, ms)
        },
        calculateDayEndDate(date) {
            const year = date.getFullYear()
            const month = date.getMonth()
            const day = date.getDate()

            return new Date(year, month, day +1, 0,0, 0, -1)
            // return new Date(year, month, day + 1)
        },
        calculateDayStartDate(date) {
            const year = date.getFullYear()
            const month = date.getMonth()
            const day = date.getDate()

            // return new Date(year, month, day, 0,0, 0, 1)
            return new Date(year, month, day)
        },
    }
}

export default calculateMixins
