import constants from '@/constants'

const SettingsRouter = [
    {
        path: '/email-accounts',
        name: 'EmailAccounts',
        meta: {
            auth: true,
            roles: [constants.userRoles.superAdmin, constants.userRoles.admin],
            title: 'Email accounts'
        },
        component: () => import('@/pages/settings/EmailAccounts')
    },
    {
        path: '/settings',
        name: 'Settings',
        meta: {
            auth: true,
            roles: [constants.userRoles.superAdmin, constants.userRoles.admin, constants.userRoles.accountant],
            title: 'Settings'
        },
        component: () => import('@/pages/settings/GeneralSettings')
    },
    {
        path: '/notifications',
        name: 'Notifications',
        meta: {
            auth: true,
            roles: [constants.userRoles.superAdmin, constants.userRoles.admin, constants.userRoles.moderator, constants.userRoles.accountant],
            title: ''
        },
        component: () => import('@/pages/settings/Notifications')
    },
    // {
    //     path: '/price-request',
    //     name: 'PriceRequestForm',
    //     meta: {
    //         auth: true,
    //         roles: [constants.userRoles.superAdmin, constants.userRoles.admin, constants.userRoles.moderator, constants.userRoles.accountant],
    //         // title: 'PriceRequestForm'
    //     },
    //     component: () => import('@/pages/settings/PriceRequestForm')
    // },
]

export default SettingsRouter