<template>
  <div class="p-mt-4 p-text-center" v-if="!itemsArrayIsEmpty">
    {{$t('Page')}} {{ pagination.currentPage }} / {{ pagination.pageCount }}.
    {{$t('Total records')}}: {{ pagination.totalCount }}
  </div>
</template>

<script>
export default {
  name: 'DataRecordsInfo',
  props: {
    pagination: Object,
    itemsArrayIsEmpty: Boolean
  }
}
</script>

<style scoped>

</style>