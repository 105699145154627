export default [
    {
        id: 1,
        label: {
            en: 'Good customer',
            et: 'Hea klient',
            ru: 'Хороший клиент',
        },
        color: 'green'
    },
    {
        id: 2,
        label: {
            en: 'Bad customer',
            et: 'Halb klient',
            ru: 'Плохой клиент',
        },
        color: 'red'
    },
    {
        id: 3,
        label: {
            en: 'Doubtful customer',
            et: 'Kahtlane klient',
            ru: 'Сомнительный клиент',
        },
        color: 'yellow'
    },
    {
        id: 0,
        label: {
            en: 'Remove status',
            et: 'Remove status',
            ru: 'Удалить статус',
        },
        color: 'grey'
    },
    {
        id: null,
        label: {
            en: 'Show history',
            et: 'Show history',
            ru: 'Show history',
        },
        // color: 'grey'
    },
]