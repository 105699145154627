<template>
  <div v-if="!itemsArrayIsEmpty" class="p-datatable p-datatable-sm p-datatable-hoverable-rows " :class="{'p-datatable-striped':striped, 'datatable-is-loading':dataTableIsLoading}">
    <div v-if="!itemsArrayIsEmpty && showHeader" class="p-datatable-header" :class="{'datatable-header--highlight': hightlightTable}">
      <div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between p-ai-center">
        <h5 class="p-m-0">{{ tableName }}</h5>
        <div v-show="showSearchInput" class="p-input-icon-left">
          <i class="pi pi-search" />
          <InputText @input="searchItems" v-model="searchInputData" :placeholder="$t('Search') + '...'" />
        </div>
      </div>
    </div>
    <div style="width: 100%;" v-if="mastersTime" class="datatable-masters-time">{{ mastersTime }}</div>
    <div class="p-datatable-wrapper">
      <table class="own-datatable">
        <thead class="p-datatable-thead">
        <tr>
          <slot name="multiselectHeader"></slot>
          <template v-for="(header, index) of headers" :key="index">
            <th v-if="!header.isHidden"
                :style="{width: header.width, 'min-width': header.minWidth}"
                :class="[{'p-highlight':sort?.column === header.name} , {'p-sortable-column':header.sortable}]"
                @click="sortItems(header.name, header.sortable)">
              <span :class="header.class">{{ header.title }}</span>
              <ColumnSorting v-show="header.sortable" :columnName="header.name" :sort="sort"/>
            </th>
          </template>

<!--          <th v-if="showButtons" :class="[{'table__actions-column-header&#45;&#45;three-items': actionButtonsCount === 3}, {'table__actions-column-header&#45;&#45;two-items': actionButtonsCount === 2}, {'table__actions-column-header&#45;&#45;one-item': actionButtonsCount === 1}]"></th>-->
          <th v-if="!!actionButtonsCount" :class="[{'table__actions-column-header--three-items': actionButtonsCount === 3}, {'table__actions-column-header--two-items': actionButtonsCount === 2}, {'table__actions-column-header--one-item': actionButtonsCount === 1}]"></th>
<!--          <th v-if="showButtons" class="table__actions-column-header&#45;&#45;three-items"></th>-->
        </tr>
        </thead>
        <tbody class="p-datatable-tbody">
          <slot name="body"></slot>
        </tbody>
      </table>
    </div>
<!--    <div v-else style="background-color: green" class="p-text-center p-m-2 p-p-6">{{ $t('No data available') }}</div>-->
  </div>
  <div v-else-if="!dataTableIsLoading" class="no-data-block">{{ $t('No data available') }}</div>
</template>

<script>
export default {
  name: 'DesktopDataTable',
  emits: ['sort-items', 'search-items', 'sortItems', 'searchItems'],
  props: {
    dataTableIsLoading: {
      type: Boolean,
      default: false
    },
    hightlightTable: Boolean,
    mastersTime: String,
    itemsArrayIsEmpty: Boolean,
    tableName: String,
    sort: Object,
    headers: Array,
    searchData: String,
    actionButtonsCount: Number,
    showHeader: {
      type: Boolean,
      default: true
    },
    showSearchInput: {
      type: Boolean,
      default: true
    },
    striped: {
      type: Boolean,
      default: true
    },
    showButtons: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dataIsEmpty: false,
      searchInputData: this.searchData,
    }
  },
  watch: {
    searchData() {
      this.searchInputData = this.searchData
    },
    // itemsArrayIsEmpty() {
    //   if (this.itemsArrayIsEmpty) {
    //     this.dataIsEmpty = true
    //   } else {
    //     this.dataIsEmpty = false
    //   }
    // }
  },
  methods: {
    sortItems(columnName, sortable) {
      if (sortable) this.$emit('sort-items', columnName)
    },
    searchItems() {
      this.$emit('search-items', this.searchInputData)
    }
  }
}
</script>

<style lang="scss" scoped>
.own-datatable {
  width: 100%;
  border-collapse: collapse!important;
}

.no-data-block {
  padding: 12vh 0;
  //height: 100%;
  text-align: center;
}

.p-datatable-wrapper {
  overflow-x: auto;
}

.id-column-header {
  width: 100px;
}

.table__active-row {
  //background-color: transparentize(green, 0.7) !important;
}


.table__actions-column-header--three-items {
  width: 135px;
}

.table__actions-column-header--two-items {
  width: 96px;
}

.table__actions-column-header--one-item {
  width: 57px;
}


//.datatable-masters-time {
//  column-span: all;
//  background-color: pink;
//  width: 100%;
//}

//.table__actions-column-data {
//  display: flex;
//  justify-content: flex-end;
//}

</style>