const PriceRequestRouter = [
    // {
    //     path: '/price-request',
    //     name: 'PriceRequestForm',
    //     component: () => import('@/pages/price_request/PriceRequestForm_MULTISTEP.vue'),
        // redirect: { name: 'PriceRequestCustomer' },
        // children: [
        //     {
        //         path: '/price-request/customer',
        //         name: 'PriceRequestCustomer',
        //         component: () => import('@/pages/price_request/PriceRequestCustomer')
        //     },
        //     {
        //         path: '/price-request/car',
        //         name: 'PriceRequestCar',
        //         component: () => import('@/pages/price_request/PriceRequestCar')
        //     },
        //     {
        //         path: 'offers',
        //         name: 'PriceRequestDescription',
        //         component: () => import('@/pages/price_request/Description')
        //     },
        // ]
    // },
    {
        path: '/o/:hash',
        name: 'CustomerOfferLogin',
        meta: {
            // auth: false,
            title: 'Offers'
        },
        component: () => import('@/pages/price_request/CustomerOffer')
    },
    {
        path: '/ru/t/:hash',
        redirect: to => {
            return { path: '/o/' + to.params.hash}
        },
        // component: () => import('@/pages/price_request/CustomerOffer')
    },
    {
        path: '/et/t/:hash',
        redirect: to => {
            return { path: '/o/' + to.params.hash}
        },
        // component: () => import('@/pages/price_request/CustomerOffer')
    },
    {
        path: '/en/t/:hash',
        redirect: to => {
            return { path: '/o/' + to.params.hash}
        },
        // component: () => import('@/pages/price_request/CustomerOffer')
    },
    // {
    //     path: '/price-request-form/:lang',
    //     name: 'PriceRequestForm',
    //     component: () => import('@/pages/price_request/PriceRequestForm')
    // },
    {
        path: '/price-request/:lang',
        name: 'PriceRequest',
        meta: {
            // auth: false,
            title: 'Price request'
        },
        component: () => import('@/pages/price_request/PriceRequest')
    },
    // {
    //     path: '/price-request',
    //     name: 'PriceRequest',
    //     meta: {
    //         // auth: false,
    //         title: 'Price request'
    //     },
    //     component: () => import('@/pages/price_request/PriceRequest')
    // },
    // {
    //     path: '/price-request/car/:lang',
    //     name: 'PriceRequestCar',
    //     component: () => import('@/pages/price_request/PriceRequestCar')
    // },
    // {
    //     path: '/price-request/description/:lang',
    //     name: 'PriceRequestDescription',
    //     component: () => import('@/pages/price_request/PriceRequestDescription')
    // },
    // {
    //     path: '/price-request/confirmation/:lang',
    //     name: 'PriceRequestConfirmation',
    //     component: () => import('@/pages/price_request/PriceRequestConfirmation')
    // },

    // {
    //     path: '/price-request/customer',
    //     name: 'PriceRequestCustomer',
    //     component: () => import('@/pages/price_request/PriceRequestCustomer')
    // }
]

export default PriceRequestRouter