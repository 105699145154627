import constants from '@/constants'

const superAdmin = constants.userRoles.superAdmin
const admin = constants.userRoles.admin
const moderator = constants.userRoles.moderator
const accountant = constants.userRoles.accountant
const employeeWorkshopMaster = constants.userRoles.employeeWorkshopMaster
const employeeMaster = constants.userRoles.employeeMaster

const OrdersRouter = [
    {
        path: '/orders',
        name: 'Orders',
        meta: {
            auth: true,
            roles: [superAdmin, admin, moderator, accountant],
            title: 'Orders'
        },
        component: () => import('@/pages/orders/Orders')
    },
    {
        path: '/orders/:number',
        name: 'Order',
        meta: {
            auth: true,
            roles: [superAdmin, admin, moderator, accountant],
            title: 'Order'
        },
        component: () => import('@/pages/orders/Order'),
    },
    {
        path: '/orders-archive',
        name: 'OrdersArchive',
        meta: {
            auth: true,
            roles: [superAdmin, admin, moderator, accountant],
            title: 'Orders archive'
        },
        component: () => import('@/pages/orders/OrdersArchive')
    },
    {
        path: '/master-orders',
        name: 'MasterOrders',
        meta: {
            auth: true,
            roles: [employeeWorkshopMaster, employeeMaster],
            title: 'Orders'
        },
        component: () => import('@/pages/orders/MasterOrders')
    },
    {
        path: '/master-orders/:number',
        name: 'MasterOrder',
        meta: {
            auth: true,
            roles: [superAdmin,admin,moderator,accountant,employeeWorkshopMaster,employeeMaster],
            title: 'Order'
        },
        component: () => import('@/pages/orders/MasterOrder'),
    },
    // {
    //     path: '/employee-orders-archive',
    //     name: 'EmployeeOrdersArchive',
    //     meta: {
    //         auth: true,
    //         roles: [employeeWorkshopMaster, employeeMaster],
    //         title: 'Orders archive'
    //     },
    //     component: () => import('@/pages/orders/EmployeeOrdersArchive')
    // },
    // {
    //     path: '/employee-orders-archive',
    //     name: 'EmployeeOrdersArchive',
    //     meta: {
    //         auth: true,
    //         roles: [employeeWorkshopMaster, employeeMaster],
    //         title: 'Employee orders archive'
    //     },
    //     component: () => import('@/pages/orders/EmployeeOrders')
    // },
]

export default OrdersRouter