<template>
  <Button icon="ti-pencil" class="p-button-raised p-button-success action-button"/>
</template>

<script>
export default {
  name: 'EditButton'
}
</script>

<style scoped>
.action-button {
  margin: 0 3px;
}
</style>