export default {
    // en: {
    //     offerEstimated: {
    //         text: 'Hello! Your offer has been appreciated. You can schedule a maintenance by clicking the link: ',
    //         pinText: 'PIN: '
    //     },
    // },
    // et: {
    //     offerEstimated: {
    //         text: 'Tere! Teie päring on hinnatud. Saate hooldusele aja kirja panna vajutades linki: ',
    //         pinText: 'PIN: '
    //     },
    // },
    // ru: {
    //     offerEstimated: {
    //         text: 'Zdravstvujte! Vashe obrashenie oceneno. Vy mozete zapisatsja na servis po ssilke: ',
    //         pinText: 'PIN: '
    //     },
    // }

    offerEstimated: {
        en: {
            text: 'Hello! Your offer has been appreciated. You can schedule a maintenance by clicking the link: ',
            pinText: 'PIN: '
        },
        et: {
            text: 'Tere! Teie päring on hinnatud. Saate hooldusele aja kirja panna vajutades linki: ',
            pinText: 'PIN: '
        },
        ru: {
            text: 'Zdravstvujte! Vashe obrashenie oceneno. Vy mozete zapisatsja na servis po ssilke: ',
            pinText: 'PIN: '
        }
    },
    appointmentReminder: {
        en: {
            text: 'Dear customer! We are waiting for you at the workshop on {date} at {time}. Address: Punane 6. Tel: 600 1501. Be sure to use the parking clock.',
        },
        et: {
            text: 'Hea klient! Ootame Teid teenindusse {date} kell {time}. Aadress: Punane 6. Tel: 600 1501. Parkimiskella kasutamine on kohustuslik.',
        },
        ru: {
            text: 'Uvazaemij klient! Zdjom vas na servis {date} v {time}. Adres: Punane 6. Tel: 600 1501. Objazatelnoe ispolzovanie parkovochnih chasov.',
        }
    },
}