<template style="width: 100%;">
  <div style="width: 100%;" class="p-datatable p-datatable-sm p-datatable-hoverable-rows p-datatable-striped">
    <div class="p-datatable-header">
      <div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between p-ai-center">
        <slot name="multiselectHeader"></slot>
        <h5 class="p-my-1">{{ tableName }}</h5>
        <div v-show="showSearchInput" class="p-input-icon-left p-my-1">
          <i class="pi pi-search" />
          <InputText @input="searchItems" v-model="searchInputData" :placeholder="$t('Search') + '...'" />
        </div>
      </div>
    </div>
    <div v-if="!dataIsEmpty" class="p-datatable-wrapper">
      <table class="mobile-datatable">
        <tbody class="p-datatable-tbody">
          <slot name="body"></slot>
        </tbody>
      </table>
    </div>
    <div v-else class="p-text-center p-m-2 p-p-6">{{ $t('No data available') }}</div>
  </div>
</template>

<script>

export default {
  name: 'MobileDataTable',
  emits: ['search-items', 'searchItems'],
  props: {
    itemsArrayIsEmpty: Boolean,
    tableName: String,
    searchData: String,
    showSearchInput: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      dataIsEmpty: false,
      searchInputData: this.searchData,
    }
  },
  watch: {
    searchData() {
      this.searchInputData = this.searchData
    },
    itemsArrayIsEmpty() {
      if (this.itemsArrayIsEmpty) {
        this.dataIsEmpty = true
      } else {
        this.dataIsEmpty = false
      }
    }
  },
  methods: {
    searchItems() {
      this.$emit('search-items', this.searchInputData)
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile-datatable {
  width: 100%;
}
</style>