<template>
  <Modal :visible="visible"
         :showCloseButton="false"
         :style="computedWindowSize"
         class="p-fluid"
         @close="continueSession">
    <template v-slot:header>
<!--      <div class="p-dialog-title">{{ $t('Confirm') }}</div>-->
<!--      <div class="p-dialog-title">Завершение сеанса</div>-->
      <div class="p-dialog-title">Ending a session</div>
    </template>
    <div class="p-formgrid p-grid p-pt-2">
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12">
        <div class="p-d-flex p-ai-center">
          <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
<!--          <span>{{ $t('Are you sure you want to proceed?') }}</span>-->
<!--          <span>Ваш сеанс завершиться через <b>{{logoutCountdownTimer}}</b> секунд. Желаете продолжить?</span>-->
          <span>Your session will end in <b>{{logoutCountdownTimer}}</b> seconds. Would you like to continue?</span>
        </div>
      </div>
    </div>
    <template #footer>
      <Button :label="$t('End session')" icon="pi pi-times" class="p-button-text p-button-danger" @click="endSession"/>
      <Button :label="$t('Continue')" icon="pi pi-check" class="" @click="continueSession" />
    </template>
  </Modal>
</template>

<script>
export default {
  // name: 'ConfirmModal',
  emits: ['end-session', 'continue-session'],
  props: {
    data: String,
    logoutCountdownTimer: Number,
    header: null,
    footer: null,
    visible: Boolean,
    style: Object,
  },
  methods: {
    endSession() {
      this.$emit('end-session')
      this.submitted = false
    },
    continueSession() {
      this.$emit('continue-session')
    }
  },
  computed: {
    // computedWindowSize() {
    //   if (this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
    //     return {position: 'relative', top: '-10vh', width: '85%', marginTop: '10px'}
    //   } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
    //     return {position: 'relative', top: '-10vh', width: '450px', marginTop: '25px'}
    //   } else {
    //     return {position: 'relative', top: '-10vh', width: '450px', marginTop: '50px'}
    //   }
    // },
    computedWindowSize() {
      if (this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {width: '85%'}
      } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {width: '450px'}
      } else {
        return {width: '450px'}
      }
    }
  }
}
</script>