'use strict'

const overlayVisibilityMixins = {
    methods: {
        // toogleCalendarOverlayVisibility(boolean, useTimeout = true) {
        toogleDropdownOverlayVisibility(boolean) {
            // console.log(boolean)
            if (boolean) {
                setTimeout(() => this.$store.commit('toggleDropdownOverlayVisibility', true), 15)
                // this.$store.commit('toggleDropdownOverlayVisibility', true)
            } else {
                setTimeout(() => this.$store.commit('toggleDropdownOverlayVisibility', false), 5)
                // if (useTimeout) {
                //     setTimeout(() => this.$store.commit('toggleDropdownOverlayVisibility', false))
                // } else {
                //     this.$store.commit('toggleDropdownOverlayVisibility', false)
                // }
            }
        }
    }
}

export default overlayVisibilityMixins
