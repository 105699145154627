'use strict'
import router from '@/router'
import httpClient from '@/services/http.services'

const authService = {
  user: null,
  async login(formData) {
    try {
      const { status, data } = await httpClient.post('site/login', formData)
      if (status === 200 && data) {
        localStorage.setItem('lastAction', Date.now());
        this.setUser(data)
        return { success: true }
      }
    } catch(err) {
      return {
        success: false,
        errors: err.response?.data?.errors,
        err
      }
    }
  },
  async register(formData) {
    try {
      const { status } = await httpClient.post('site/signup', formData)
      if (status === 200) {
        return { success: true }
      }
    } catch(err) {
      return {
        success: false,
        errors: err.response.data.errors
      }
    }
  },
  async logout() {
    // const { status } = await httpClient.get('site/logout')
    // if (status === 200) {
    //   localStorage.removeItem('ACCESS_TOKEN')
    //   router.push({ name: 'Login' })
    // }
    localStorage.removeItem('ACCESS_TOKEN')
    router.push({ name: 'Login' })
  },
  setUser(user) {
    this.user = user
    localStorage.setItem('ACCESS_TOKEN', window.btoa(user.access_token))
    // const btoa = window.btoa(user.access_token)
    // const atob = window.atob(btoa)
    // console.log(btoa)
    // console.log(atob)
  },
  isLoggedIn() {
    return !!localStorage.getItem('ACCESS_TOKEN')
  },
  // async getUserRole () {
  //   try {
  //     const { status, data } = await httpClient.get('user/data')
  //     if (status === 200) {
  //       return { success: true, role: data.role }
  //     }
  //   } catch (err) {
  //     return {
  //       success: false,
  //       errors: err.response.data.errors
  //     }
  //   }
  // },
  async requestPasswordReset(email) {
    try {
      const { status } = await httpClient.post('user/request-password-reset', { email: email }
      )
      if (status === 200) {
        return { success: true }
      }
    } catch (err) {
      return { success: false, errors: err.response.data.errors }
    }
  },
  async resetPassword(token, pass) {
    try {
      const { status } = await httpClient.post(
        `user/reset-password?token=${token}`,
        { password: pass }
      )
      if (status === 200) {
        return { success: true }
      }
    } catch(err) {
      this.showError(err)
      return {
        success: false,
        errors: err.response.data.errors,
        tokenErrorMessage: err.response.data.message
      }
    }
  },
  getToken() {
    if (localStorage.getItem('ACCESS_TOKEN')) {
      return window.atob(localStorage.getItem('ACCESS_TOKEN'))
    } else {
      return false
    }
  },
  async getUserData() {
    try {
      const { status, data } = await httpClient('user/get-data?expand=cashboxes,branch,emailAccounts')
      if (status === 200) {
        return {
          success: true,
          userData: data
        }
      }
    } catch(err) {
      localStorage.removeItem('ACCESS_TOKEN')
      router.push({ name: 'Login' })
      return {
        success: false,
        err
      }
    }
  },
  async getUserRole() {
    try {
      const { status, data } = await httpClient('user/data')
      if (status === 200) {
        // return this.computedUserRole(data.role)
        console.log(data)
        return 'admin'
      }
    } catch (err) {
      console.log(err)
    }
  },
  // computedUserRole(roleId) {
  //   switch (roleId) {
  //     case 0: return 'superAdmin';
  //     case 1: return 'admin';
  //     case 3: return 'moderator';
  //     default: return 'n/a'
  //   }
  // }
}
export default authService
