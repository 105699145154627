import constants from '@/constants'
const superAdmin = constants.userRoles.superAdmin
const admin = constants.userRoles.admin
const moderator = constants.userRoles.moderator
const accountant = constants.userRoles.accountant

const DataDirectoryRouter = [
    {
        path: '/suppliers',
        name: 'Suppliers',
        meta: {
            auth: true,
            roles: [superAdmin, admin, accountant],
            title: 'Suppliers'
        },
        component: () => import('@/pages/data_directory/Suppliers')
    },
    {
        path: '/taxes',
        name: 'Taxes',
        meta: {
            auth: true,
            roles: [superAdmin, admin, accountant],
            title: 'Taxes'
        },
        component: () => import('@/pages/data_directory/Taxes')
    },
    {
        path: '/areas',
        name: 'Areas',
        meta: {
            auth: true,
            roles: [superAdmin, admin],
            title: 'Working areas'
        },
        component: () => import('@/pages/data_directory/Areas')
    },
    {
        path: '/cashboxes',
        name: 'Cashboxes',
        meta: {
            auth: true,
            roles: [superAdmin, admin, accountant],
            title: 'Cashboxes'
        },
        component: () => import('@/pages/data_directory/Cashboxes')
    },
    {
        path: '/services',
        name: 'Services',
        meta: {
            auth: true,
            roles: [superAdmin, admin, accountant],
            title: 'Services'
        },
        component: () => import('@/pages/data_directory/Services.vue')
    },
    {
        path: '/warehouses',
        name: 'Warehouses',
        meta: {
            auth: true,
            roles: [superAdmin, admin, accountant],
            title: 'Warehouses'
        },
        component: () => import('@/pages/data_directory/Warehouses.vue')
    },
    {
        path: '/links-settings',
        name: 'LinksSettings',
        meta: {
            auth: true,
            roles: [superAdmin, admin, accountant],
            title: 'Links settings'
        },
        component: () => import('@/pages/data_directory/LinksSettings.vue')
    },
    {
        path: '/links',
        name: 'Links',
        meta: {
            auth: true,
            roles: [superAdmin, admin, moderator, accountant],
            title: 'Links'
        },
        component: () => import('@/pages/data_directory/Links.vue')
    }
]

export default DataDirectoryRouter