<template>
<!--  <div>-->
<!--    <ul>-->
<!--      <li>-->
<!--        <ul>Main-->
<!--          <li>Main</li>-->
<!--        </ul>-->
<!--      </li>-->
<!--    </ul>-->
<!--  </div>-->
	<ul v-if="items" role="menu">
        <template v-for="(item,i) of items">
            <li v-if="visible(item) && !item.separator" :key="item.label[$i18n.locale] || i" :class="[{'layout-root-menuitem': root, 'active-menuitem': activeIndex === i && !item.disabled, 'active-slim-menu-item': item.active && root && isSlim()}]" role="none">
                <div class='arrow' v-if="item.items && root===true"></div>
                <router-link v-if="item.to" :to="item.to" :style="item.style" :class="[item.class, 'p-ripple', {'p-disabled': item.disabled}]" :target="item.target" exact role="menuitem"
                             @click="onMenuItemClick($event,item,i)" @mouseenter="onMenuItemMouseEnter(i)" v-ripple>
                    <i :class="['layout-menuitem-icon', item.icon]"></i>
                     <span class="layout-menuitem-text">{{item.label[$i18n.locale]}}</span>
                    <i v-if="item.items" class="pi pi-fw pi-angle-down layout-menuitem-toggler pi-arrow__position-changed"></i>
                    <span v-if="item.badge" class="menuitem-badge">{{item.badge}}</span>
                </router-link>
                <a v-if="!item.to" :href="item.url||'#'" :style="item.style" :class="[item.class, 'p-ripple', {'p-disabled': item.disabled, 'active-horizontal-menu-link': item.active && root && isHorizontal()}]" :target="item.target" role="menuitem"
                   @click="onMenuItemClick($event,item,i)" @mouseenter="onMenuItemMouseEnter(i)" v-ripple>
                    <i :class="['layout-menuitem-icon', item.icon]"></i>
                     <span class="layout-menuitem-text">{{item.label[$i18n.locale]}}</span>
                    <i v-if="item.items" class="pi pi-fw pi-angle-down layout-menuitem-toggler"></i>
                    <span v-if="item.badge" class="menuitem-badge">{{item.badge}}</span>
                </a>
				<div v-if="root" class="layout-menuitem-root-text" style="text-transform: uppercase">{{item.label[$i18n.locale]}}</div>
                <transition name="layout-submenu-container">
                    <appsubmenu v-show="item.items && (root && !((isHorizontal() || isSlim()) && (!isSlim() || (isSlim() && activeIndex !== null))) ? true : activeIndex === i)"
                                :items="visible(item) && item.items"
                                :menuActive="menuActive"
                                :layoutMode="layoutMode"
                                :parentMenuItemActive="activeIndex === i"
                                @menuitem-click="$emit('menuitem-click', $event)">

                    </appsubmenu>
                </transition>
            </li>
            <li class="p-menu-separator" :style="item.style" v-if="visible(item) && item.separator" :key="'separator' + i" role="separator"></li>
        </template>
	</ul>
</template>
<script>
import EventBus from './event-bus';

export default {
	name: 'appsubmenu',
	emits: ['root-menuitem-click', 'menuitem-click'],
	props: {
		items: Array,
		layoutMode: String,
		menuActive: Boolean,
		root: {
			type: Boolean,
			default: false
		},
		parentMenuItemActive: {
			type: Boolean,
			default: false
		}
	},
  watch: {
    items() {
      this.checkActiveHorizontalMenuLink()
    },
    '$route'() {
      this.checkActiveHorizontalMenuLink()
    }
    // router-link-exact-active
  },
	data() {
		return {
			activeIndex : null
		}
	},
	mounted() {
		EventBus.on('reset-active-index', () => {
			if((this.layoutMode === 'horizontal' || this.layoutMode === 'slim')) {
				this.activeIndex = null;
			}
		});
	},
	methods: {
    checkActiveHorizontalMenuLink() {
      this.items?.forEach(item => {
        item.active = false
        if (item.items?.length) { //Sub items
          item.items.forEach(subItem => {
            // console.log('to:' + i.to, 'route:' + this.$route.path.split('/')[1])
            // console.log(this.$route.path.split('/')[1])
            // console.log((i.to).split('/')[1], `${this.$route.path.split('/')[1]}`)
            // console.log('route:' + this.$route.path)
            // const fistRouteWord =
            // if ((i.to).split('/')[1] === (this.$route.path).split('/')[1]) {
            //   item.active = true
            // } else if ((i.to).split('-')[0] === (this.$route.path).split('-')[0]) (
            //   item.active = true
            // )
            if ((subItem.to).split('/')[1] === this.$route.path.split('/')[1] ||
                (subItem.to).split('-')[0] === (this.$route.path).split('-')[0]) {
              item.active = true

            }
          })
        }
      })
    },
		onMenuItemClick(event, item, index) {
      // if (!this.root) {
      //   this.checkActiveHorizontalMenuLink()
      // }

			if (item.disabled) {
				event.preventDefault();
				return;
			}

			//execute command
			if (item.command) {
                item.command({originalEvent: event, item: item});
                event.preventDefault();
            }

            if (item.items) {
                event.preventDefault();
            }

			if (this.root) {
				this.$emit('root-menuitem-click', {
					originalEvent: event
				});
			}

      if (item.items) {
          this.activeIndex = index === this.activeIndex ? null : index;
			} else {
				const ink = this.getInk(event.currentTarget);
				if (ink) {
					this.removeClass(ink, 'p-ink-active');
				}
			}

			this.$emit('menuitem-click', {
				originalEvent: event,
				item: item
			});
		},
		getInk(el) {
			for (let i = 0; i < el.children.length; i++) {
				if (typeof el.children[i].className === 'string' && el.children[i].className.indexOf('p-ink') !== -1) {
					return el.children[i];
				}
			}
			return null;
		},
		removeClass(element, className) {
			if (element.classList)
				element.classList.remove(className);
			else
				element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
		},
		isMobile() {
			return window.innerWidth <= 640;
		},
		isHorizontal() {
			return this.layoutMode === 'horizontal';
		},
		isSlim() {
			return this.layoutMode === 'slim';
        },
		onMenuItemMouseEnter(index) {
			if(this.root && this.menuActive && (this.layoutMode === 'horizontal' || this.layoutMode === 'slim') && !this.isMobile()) {
				this.activeIndex =  index;
			}
        },
        visible(item) {
            return (typeof item.visible === 'function' ? item.visible() : item.visible !== false);
        }
	}
}
</script>
<style scoped>
.layout-menuitem-toggler {
  position: relative;
  top: 1px;
}
</style>
