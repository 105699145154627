import constants from '@/constants'

// export default {
//     en: [
//         {num: constants.userRoles.superAdmin, name: 'Super administrator'},
//         {num: constants.userRoles.admin, name: 'Administrator'},
//         {num: constants.userRoles.moderator, name: 'Moderator'},
//         {num: constants.userRoles.accountant, name: 'Accountant'},
//         {num: constants.userRoles.customer, name: 'Customer'},
//         {num: constants.userRoles.employeeWorkshopMaster, name: 'Master'},
//         {num: constants.userRoles.employeeMaster, name: 'Workshop foreman'},
//     ],
//     et: [
//         {num: constants.userRoles.superAdmin, name: 'Superadministraator'},
//         {num: constants.userRoles.admin, name: 'Administraator'},
//         {num: constants.userRoles.moderator, name: 'Moderaator'},
//         {num: constants.userRoles.accountant, name: 'Raamatupidaja'},
//         {num: constants.userRoles.customer, name: 'Klient'},
//         {num: constants.userRoles.employeeWorkshopMaster, name: 'Meister'},
//         {num: constants.userRoles.employeeMaster, name: 'Töökoja meister'},
//     ],
//     ru: [
//         {num: constants.userRoles.superAdmin, name: 'Супер администратор'},
//         {num: constants.userRoles.admin, name: 'Администратор'},
//         {num: constants.userRoles.moderator, name: 'Модератор'},
//         {num: constants.userRoles.accountant, name: 'Бухгалтер'},
//         {num: constants.userRoles.customer, name: 'Клиент'},
//         {num: constants.userRoles.employeeWorkshopMaster, name: 'Мастер'},
//         {num: constants.userRoles.employeeMaster, name: 'Мастер цеха'},
//     ]
// }

// export default [
//     {
//         num: constants.userRoles.superAdmin,
//         name: {
//             en: 'Super administrator',
//             et: 'Superadministraator',
//             ru: 'Супер администратор',
//         }
//     },
//     {
//         num: constants.userRoles.admin,
//         name: {
//             en: 'Administrator',
//             et: 'Administraator',
//             ru: 'Администратор',
//         }
//     },
//     {
//         num: constants.userRoles.moderator,
//         name: {
//             en: 'Moderator',
//             et: 'Moderaator',
//             ru: 'Модератор',
//         }
//     },
//     {
//         num: constants.userRoles.accountant,
//         name: {
//             en: 'Accountant',
//             et: 'Raamatupidaja',
//             ru: 'Бухгалтер',
//         }
//     },
//     {
//         num: constants.userRoles.customer,
//         name: {
//             en: 'Customer',
//             et: 'Klient',
//             ru: 'Клиент',
//         }
//     },
//     {
//         num: constants.userRoles.employeeWorkshopMaster,
//         name: {
//             en: 'Workshop foreman',
//             et: 'Töökoja meister',
//             ru: 'Мастер цеха',
//         }
//     },
//     {
//         num: constants.userRoles.employeeMaster,
//         name: {
//             en: 'Master',
//             et: 'Meister',
//             ru: 'Мастер',
//         }
//     },
// ]


export default [
        {
            num: constants.userRoles.superAdmin,
            name: {
                en: 'Super administrator',
                et: 'Superadministraator',
                ru: 'Супер администратор'
            }
        },
        {
            num: constants.userRoles.admin,
            name: {
                en: 'Administrator',
                et: 'Administraator',
                ru: 'Администратор'
            }
        },
        {
            num: constants.userRoles.moderator,
            name: {
                en: 'Moderator',
                et: 'Moderaator',
                ru: 'Модератор'
            }
        },
        {
            num: constants.userRoles.accountant,
            name: {
                en: 'Accountant',
                et: 'Raamatupidaja',
                ru: 'Бухгалтер'
            }
        },
        {
            num: constants.userRoles.customer,
            name: {
                en: 'Customer',
                et: 'Klient',
                ru: 'Клиент'
            }
        },
        {
            num: constants.userRoles.employeeWorkshopMaster,
            name: {
                en: 'Workshop foreman',
                et: 'Töökoja meister',
                ru: 'Мастер цеха'
            }
        },
        {
            num: constants.userRoles.employeeMaster,
            name: {
                en: 'Master',
                et: 'Meister',
                ru: 'Мастер'
            }
        },
]