import {createRouter, createWebHistory } from 'vue-router'
import authService from '@/services/auth.service'
import UsersRouter from '@/pages/users/router'
import OrdersRouter from '@/pages/orders/router'
import OffersRouter from "@/pages/offers/router";
import DataDirectoryRouter from '@/pages/data_directory/router'
import FinanceRouter from '@/pages/finance/router'
import CarsRouter from '@/pages/cars/router'
import WarehouseRouter from '@/pages/warehouse/router'
import MailRouter from "@/pages/messages/router";
import SettingsRouter from "@/pages/settings/router";
import PriceRequestRouter from "@/pages/price_request/router";
import constants from "@/constants";

const superAdmin = constants.userRoles.superAdmin
const admin = constants.userRoles.admin
const moderator = constants.userRoles.moderator
const accountant = constants.userRoles.accountant
const employeeWorkshopMaster = constants.userRoles.employeeWorkshopMaster
const employeeMaster = constants.userRoles.employeeMaster

const routes = [
	{
		path: '/',
		name: 'Dashboard',
		exact: true,
        meta: {
            auth: true,
            roles: [superAdmin, admin, moderator, accountant, employeeWorkshopMaster, employeeMaster],
            title: 'Dashboard'
        },
		component: () => import('./pages/statistics/Dashboard.vue')
	},
	{
        path: '/login',
        name: 'Login',
        component: () => import('./pages/Login.vue')
    },
	// {
    //     path: '/register',
    //     name: 'Register',
    //     component: () => import('./pages/Register.vue')
    // },
	{
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: () => import('./pages/ForgotPassword.vue')
    },
    {
        path: '/error',
        name: 'Error',
        component: () => import('./pages/Error.vue')
    },
    {
        path: '/notfound',
        name: 'NotFound',
        component: () => import('./pages/NotFound.vue')
    },
    {
        path: '/access',
        name: 'Access',
        component: () => import('./pages/Access.vue')
    },
    ...UsersRouter,
    ...OrdersRouter,
    ...OffersRouter,
    ...FinanceRouter,
    ...DataDirectoryRouter,
    ...CarsRouter,
    ...WarehouseRouter,
    ...MailRouter,
    ...SettingsRouter,
    ...PriceRequestRouter
];

const router = createRouter({
	// history: createWebHashHistory(),
	// history: createMemoryHistory(),
	history: createWebHistory(),
	routes
});


// const role = authService.getToken()
// const role = 'admin'
// console.log(role)

// const role = 'admin'

// router.beforeEach((to, from, next) => {
// 	if (to.meta.auth && !authService.isLoggedIn()) {
// 		next({ name: 'Login' })
// 	} else {
// 		if (to.meta.roles && (to.meta.roles).indexOf('admin1') < 0) {
// 			next({ name: 'Access' })
// 		} else {
// 			next()
// 		}
// 	}
// })

router.beforeEach((to, from, next) => {
	if (to.meta.auth && !authService.isLoggedIn()) {
		next({ name: 'Login' })
	} else {
		next()
	}
})

export default router;
