<template>
	<div class="exception-body  error-body">
		<div class="exception-container">
			<img src="assets/layout/images/logo-dark.png"/>
			<h1>Server Error</h1>
			<p>
				Connection lost. Please refresh the page.
			</p>
      <Button label="Refresh page" icon="pi pi-refresh" @click="refreshPage"></Button>
		</div>
	</div>
</template>

<script>
	export default {
    // emits: ['refresh-page'],
		methods: {
      refreshPage() {
        document.location.reload()
        // this.$emit('refresh-page');
      },
		}
	}
</script>

<style scoped>

</style>