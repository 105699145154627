import constants from '@/constants'

const OffersRouter = [
    {
        path: '/offers',
        name: 'Offers',
        meta: {
            auth: true,
            roles: [constants.userRoles.superAdmin, constants.userRoles.admin, constants.userRoles.moderator, constants.userRoles.accountant],
            title: 'Offers'
        },
        component: () => import('@/pages/offers/Offers')
    },
]

export default OffersRouter