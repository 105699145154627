<template>
  <div class="datatable-wrapper" v-if="computedActualPositions?.length">
    <template v-if="!$store.state.mobileLayout">
      <table class="p-datatable p-datatable-striped p-datatable-sm p-datatable-hoverable-rows positions-table">
        <thead class="p-datatable-tbody"
               :class="{ 'p-datatable-thead' : !isSpoilerTable }">
          <tr class="table-opened-info__header-row">
            <th>#</th>
            <th style="min-width: 200px">{{ $t('Description') }}</th>
            <th>{{ $t('Qty') }}</th>
            <th>{{ $t('Price') }}</th>
            <th v-if="showDiscountRow">{{ $t('Discount') }}</th>
            <th>{{ $t('Sum') }}</th>
          </tr>
        </thead>
        <tbody class="p-datatable-tbody">
          <tr v-for="(position, index) of computedActualPositions" :key="position.id">
            <td>{{ ++index }}.</td>
            <td>{{  position.name }}</td>
            <td>{{  position.qty }}</td>
            <td>{{ formatCurrency(position.sell_price) }}</td>
            <td v-if="showDiscountRow">
              <span v-if="position.discount && +position.discount">{{ formatDecimal(position.discount) }}%</span>
              <i v-else class="ti-minus"></i>
            </td>
            <td>{{ formatCurrency(position.sum) }}</td>
          </tr>
          <tr v-if="item.consumable">
            <td>{{ computedActualPositions?.length ? computedActualPositions?.length + 1  : 1 }}.</td>
            <td>{{ item.consumable.name }}</td>
            <td>{{ item.consumable.qty }}</td>
            <td>{{ formatCurrency(item.consumable.sell_price) }}</td>
            <td v-if="showDiscountRow">
              <span v-if="item.consumable.discount && +item.consumable.discount">{{ formatDecimal(item.consumable.discount) }}%</span>
              <i v-else class="ti-minus"></i>
            </td>
            <td>{{ formatCurrency(item.consumable.sum) }}</td>
          </tr>

          <tr class="table-opened-info__row--total" v-if="computedTaxesArray?.length">
            <td class="no-bottom-border p-text-right" :colspan="showDiscountRow ? 5 : 4">{{ $t('Sum without tax') }}:</td>
            <td class="no-bottom-border">{{ formatCurrency(item.sum) }}</td>
          </tr>

          <template v-if="computedTaxesArray?.length">
            <tr class="table-opened-info__row--total" v-for="(tax, index) of computedTaxesArray" :key="index">
              <template v-if="tax.value && +tax.value !== 0">
                <td class="no-bottom-border p-text-right" :colspan="showDiscountRow ? 5 : 4">{{ $t('Tax') }} ({{ tax.value }}%):</td>
                <td class="no-bottom-border">{{ formatCurrency(tax.sum) }}</td>
              </template>
            </tr>
          </template>

          <tr class="table-opened-info__row--total">
            <td class="no-bottom-border p-text-right" :colspan="showDiscountRow ? 5 : 4"><b>{{ $t('Total sum') }}:</b></td>
            <td class="no-bottom-border"><b>{{ formatCurrency(item.grand_total) }}</b></td>
          </tr>
        </tbody>
      </table>
    </template>


    <template v-else-if="$store.state.mobileLayout">
      <table class="p-datatable p-datatable-striped p-datatable-sm p-datatable-hoverable-rows positions-table">
        <tbody class="p-datatable-tbody">
          <tr v-for="(position, index) of computedActualPositions" :key="position.id">
            <td>{{ ++index }}.</td>
            <td>
              <div>
                <span class="title">{{ $t('Description') }}: </span>{{  position.name }}
              </div>
              <div>
                <div>
                  <span class="title">{{ $t('Qty') }}: </span>{{  position.qty }}
                </div>
                <div>
                  <span class="title">{{ $t('Price') }}: </span>{{ formatCurrency(position.sell_price) }}
                </div>
                <div v-if="position.discount && +position.discount">
                  <span class="title">{{ $t('Discount') }}: </span>
                  {{ formatDecimal(position.discount) }}%
                </div>
                <div>
                  <span class="title">{{ $t('Sum') }}: </span> {{ formatCurrency(position.sum) }}
                </div>
              </div>
            </td>
  <!--          <td>-->
  <!--            <span v-if="position.discount && +position.discount">{{ formatDecimal(position.discount) }}%</span>-->
  <!--            <i v-else class="ti-minus"></i>-->
  <!--          </td>-->
  <!--          <td>{{ formatCurrency(position.sum) }}</td>-->
          </tr>
<!--        <tr v-if="item.consumable">-->
<!--          <td>{{ computedActualPositions?.length ? computedActualPositions?.length + 1  : 1 }}.</td>-->
<!--          <td>{{ item.consumable.name }}</td>-->
<!--          <td>{{ item.consumable.qty }}</td>-->
<!--          <td>{{ formatCurrency(item.consumable.sell_price) }}</td>-->
<!--          <td>-->
<!--            <span v-if="item.consumable.discount && +item.consumable.discount">{{ formatDecimal(item.consumable.discount) }}%</span>-->
<!--            <i v-else class="ti-minus"></i>-->
<!--          </td>-->
<!--          <td>{{ formatCurrency(item.consumable.sum) }}</td>-->
<!--        </tr>-->

          <tr v-if="item.consumable">
<!--            <td>{{ computedActualPositions?.length ? computedActualPositions?.length + 1  : 1 }}.</td>-->
            <td>{{ computedActualPositions?.length ? computedActualPositions?.length + 1  : 1 }}.</td>
            <td>
              <div>
                <span class="title">{{ $t('Description') }}: </span>{{  item.consumable.name }}
              </div>
              <div>
                <div>
                  <span class="title">{{ $t('Qty') }}: </span>{{  item.consumable.qty }}
                </div>
                <div>
                  <span class="title">{{ $t('Price') }}: </span>{{ formatCurrency(item.consumable.sell_price) }}
                </div>
                <div v-if="item.consumable.discount && +item.consumable.discount">
                  <span class="title">{{ $t('Discount') }}: </span>
                  {{ formatDecimal(item.consumable.discount) }}%
                </div>
                <div>
                  <span class="title">{{ $t('Sum') }}: </span> {{ formatCurrency(item.consumable.sum) }}
                </div>
              </div>
            </td>
          </tr>

          <tr class="table-opened-info__row--total" v-if="computedTaxesArray?.length">
            <td class="no-bottom-border p-text-right" colspan="2">{{ $t('Sum without tax') }}: {{ formatCurrency(item.sum) }}</td>
          </tr>

          <template v-if="computedTaxesArray?.length">
            <tr class="table-opened-info__row--total" v-for="(tax, index) of computedTaxesArray" :key="index">
              <template v-if="tax.value && +tax.value !== 0">
                <td class="no-bottom-border p-text-right" colspan="2">{{ $t('Tax') }} ({{ tax.value }}%): {{ formatCurrency(tax.sum) }}</td>
              </template>
            </tr>
          </template>

          <tr class="table-opened-info__row--total">
            <td class="no-bottom-border p-text-right" colspan="2"><b>{{ $t('Total sum') }}: {{ formatCurrency(item.grand_total) }}</b></td>
          </tr>
        </tbody>
      </table>
    </template>
  </div>


  <div v-else :class="{ 'datatable--small-text': isSpoilerTable }" style="text-align: center; padding: 35px;">
    {{ $t('No data available' )}}
  </div>
</template>

<script>
import constants from '@/constants'
import formatMixins from '@/mixins/formatMixins'

export default {
  mixins: [ formatMixins ],
  name: 'CustomerOfferPositionsTable',
  props: {
    item: Object,
    isSpoilerTable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // positionIndex: 1,
      // showDiscountRow: true,
      constants
    }
  },
  methods: {
    test() {
      console.log('aaa')
    },
  },
  computed: {
    computedActualPositions() {
      return this.item.positions ?? []
      // return this.item.positions?.filter(position => position.status) ?? []
    },
    showDiscountRow() {
      let boolean = false
      if (this.computedActualPositions?.length) {
        this.computedActualPositions.forEach(p => {
          if (p.discount && +p.discount) {
            boolean = true
          }
        })
      }
      return boolean
    },
    computedTaxesArray() {
      if (this.computedActualPositions?.length) {
        const taxes = []
        this.computedActualPositions.forEach(p => {
          // console.log(p)
          if (p.tax) {
            const tax = {
              id: p.tax.id ?? null,
              value: p.tax.value ?? null,
              sum: p.total - p.sum
            }
            const filteredTax = taxes.filter(t => t.value === tax.value)[0]
            if (!filteredTax) {
              taxes.push(tax)
            } else {
              filteredTax.sum += tax.sum
            }
          }
        })
        if (this.item.consumable) {
          const consumable = {
            id: this.item.consumable.tax?.id ?? null,
            value: this.item.consumable.tax?.value ?? null,
            // value: this.item.consumable.tax_value ?? null,
            sum: this.item.consumable.total - this.item.consumable.sum
          }
          // console.log(consumable)
          const filteredTax = taxes.filter(t => t.value === consumable.value)[0]
          if (!filteredTax) {
            taxes.push(consumable)
          } else {
            filteredTax.sum += consumable.sum
          }
        }
        if (taxes.length === 1) {
          taxes[0].sum = +this.item.tax
        }
        // console.log(taxes)
        return taxes
      } else {
        return []
      }
    },
    // noPositions() {
    //   return !(this.item?.positions?.filter(position => position.status).length)
    // },
  }
}
</script>

<style scoped lang="scss">
.datatable-wrapper {
  overflow-y: auto!important;
//  background-color: inherit!important;
//  //padding-bottom: 25px;
//  &:hover {
//    background-color: inherit!important;
//  }
}

.positions-table {
  margin-bottom: 15px;
  border-collapse: collapse;
  //overflow-y: auto!important;
  width: 100%;
  line-height: 1.6em;
  .title {
    font-weight: 600;
  }
  tr {
    .small-width {
      width: 6%;
    }
    .medium-width {
      width: 10%;
    }
    th {
      padding: 10px 7px;

    }
    td {
      padding: 7px;
      height: 45px;
      //background-color: inherit !important;
    }
  }
}
.data-custom-padding {
  padding: 3px !important;
}

.datatable--small-text {
  font-size: 0.92em;
}

//.table-opened-info__row {
  //background-color: red!important;
//}


.table-opened-info__row--total {
  //height: 10px!important;
  //line-height: 0.1!important;
  td {
    height: 35px!important;
    //padding: 0 7px;
    //line-height: 0.1!important;
    //background-color: red;
  }

}


.no-bottom-border {
  border-bottom: none !important;
}
</style>