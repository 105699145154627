<template>
  <Button icon="ti-eye" class="p-button-raised p-button-secondary action-button"/>
</template>

<script>
export default {
  name: 'ViewButton'
}
</script>

<style scoped>
.action-button {
  margin: 0 3px;
}
</style>