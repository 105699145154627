'use strict'
// import store from '@/store'
// 'numeric', 'long'

const formatMixins = {
    methods: {
        // formatDecimal(value, isString = true, fractionDigits = 2, showZeros = false) {
        formatDecimal(value, isString = true, fractionDigits = 2) {
            value = value ? +value : 0
            // const fixedValue = value.toFixed(fractionDigits)
            // const fixedValue = Math.round(value, fractionDigits);
            // let roundedValue = value

            // if (roundedValue < 0) {
            //     // roundedValue = Math.floor( value * 1e2 ) / 1e2;
            //     roundedValue = Math.ceil( value * 1e2 ) / 1e2;
            // } else {
            //     // roundedValue = Math.round( value * 1e2 ) / 1e2;
            //     roundedValue = Math.floor( value * 1e2 ) / 1e2;
            // }
            // const num = (value * 100) / 100
            const digitsAfterCommaLength = value.toString().split('.')[1]?.length ?? 0
            // console.log(value, digitsAfterCommaLength)
            // console.log(digitsAfterCommaLength)
            // let devideByLength = 1000
            // if (digitsAfterCommaLength === 4) {
            //     devideByLength = 1000
            // } else if (digitsAfterCommaLength === 3) {
            //     devideByLength = 100
            // }

            // if (digitsAfterCommaLength > 2) {
            //     if (value < 0) {
            //         value = Math.floor( value * devideByLength ) / devideByLength;
            //     } else {
            //         value = Math.ceil( value * devideByLength ) / devideByLength;
            //     }
            //
            //     console.log(value)
            // }

            if (digitsAfterCommaLength === 3) {
                if (value < 0) {
                    if (value.toString()[5] === '5') {
                        value -= 0.005
                    }
                    // value = Math.floor( value * 100 ) / 100
                } else {
                    if (value.toString()[4] === '5') {
                        value += 0.005
                    }
                    // value = Math.ceil( value * 100 ) / 100
                }
            }

            // const showZerosInFixedValue = digitsAfterCommaLength || showZeros
            // const fractionDigitsInFixedValue = showZerosInFixedValue ? fractionDigits : 0

            const fixedValue = value.toFixed(fractionDigits)
            if (isString) {
                return fixedValue
            } else {
                return +fixedValue
            }
        },
        formatCurrency(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
            value = value ? +value : 0
            // value = +value.toFixed(2)
            // value = this.formatDecimal(value, false)
            if (this.$store.state.branchData && this.$store.state.branchData.currency && this.$store.state.branchData.locale) {
                return (value).toLocaleString(this.$store.state.branchData.locale, {
                    style: 'currency',
                    currency: this.$store.state.branchData.currency,
                    minimumFractionDigits: minimumFractionDigits,
                    // maximumFractionDigits: 4
                    maximumFractionDigits: maximumFractionDigits
                });
            } else {
                return
            }
        },
        formatDate(date, showSeconds = false) {
            if (date && this.$store.state.branchData?.timezone) {
                const event = new Date(date * 1000);

                const options = {
                    timeZone: this.$store.state.branchData.timezone,
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: 'numeric',
                    minute: 'numeric',
                }
                if (showSeconds) {
                    options.second = 'numeric'
                }
                return event.toLocaleString(this.$store.state.branchData.locale, options)
            } else {
                return
            }
        },
        formatDay(date) {
            if (date && this.$store.state.branchData?.timezone) {
                const event = new Date(date * 1000);
                return event.toLocaleString(this.$store.state.branchData.locale, { timeZone: this.$store.state.branchData.timezone, year: 'numeric', month: '2-digit', day: '2-digit' })
            } else {
                return
            }
        },
        formatTime(date) {
            if (date && this.$store.state.branchData?.timezone) {
                const event = new Date(date * 1000);
                return event.toLocaleString(this.$store.state.branchData.locale, { timeZone: this.$store.state.branchData.timezone, hour: '2-digit', minute:'2-digit' })
            } else {
                return
            }
        },
        formatBirthDate(date) {
            if (date && this.$store.state.branchData?.timezone) {
                const event = new Date(date * 1000);
                return event.toLocaleString(this.$store.state.branchData.locale, { timeZone: this.$store.state.branchData.timezone, year: 'numeric', month: '2-digit', day: '2-digit' })
            } else {
                return
            }
        },
        formatMileage(value) {
            value = value ?? 0
            if (this.$store.state.branchData) {
                return (+value).toLocaleString(this.$store.state.branchData.locale);
            } else {
                return
            }
        },
    },
    computed: {
        computedNumberInputLocale() {
            return this.$store.state.branchData?.locale || 'en-US'
            // return 'en-US'
        },
    }
}

export default formatMixins
