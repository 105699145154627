<template>
  <Modal :style="computedWindowSize"
         :visible="visible"
         class="p-fluid"
         @close="close">
    <template v-slot:header>
      <div class="p-dialog-title">{{ $t('Appointment booking') }}</div>
    </template>

    <Spinner v-if="dataIsSending"></Spinner>
    <div v-else class="p-formgrid p-grid p-pt-3" >
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12">
        <div class="p-float-label">
<!--          @input="checkDateIsValid"-->
<!--          @blur="checkDateIsValid"-->
          <Calendar id="appointment_date"
                    style="height: 42px;"
                    @show="toogleDropdownOverlayVisibility(true)"
                    @hide="toogleDropdownOverlayVisibility(false)"
                    @date-select="selectDate"
                    :class="{'p-invalid' : !dateIsValid || (submitted && !selectedDate)}"
                    v-model="selectedDate"
                    :dateFormat="settings.dateFormat"
                    :disabledDays="computedDisabledWeekDays"
                    :disabledDates="computedDisabledDates"
                    :minDate="computedMinDateValue"
                    :maxDate="computedMaxDateValue"
                    :showIcon="true"
                    :showButtonBar="true"
                    :selectOtherMonths="true"
                    autocomplete="off"/>
<!--          <label for="appointment_date">Date<span class="warning-color">*</span></label>-->
          <label for="appointment_date" :class="{'warning-color' : !dateIsValid || (submitted && !selectedDate)}">{{ $t('Day') }}<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !selectedDate">{{ $t('Required field') }}</small>
        <small class="p-invalid" v-if="!dateIsValid">{{ $t('Invalid date format') }}</small>
      </div>



      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12" v-if="selectedDate">
        <div class="p-mb-3" v-if="workingStartTime && workingEndTime">{{ $t('Working hours')}}: {{ workingStartTime }} - {{ workingEndTime }}</div>
        <div class="p-mb-1" v-if="appointmentTimes">{{ $t('Please select time')}}:</div>
        <div class="p-d-flex p-flex-wrap">
          <div v-for="(time, index) of appointmentTimes" :key="index" >
            <Button @click="selectTime(time)"
                    style="width: 65px;"
                    class="p-button-outlined p-mr-2 p-mt-2"
                    :class="{'appointment-modal__selected-time-btn': time === startTime }"
                    :label="generateHoursStringFromTimestamp(time)"/>
          </div>
        </div>
      </div>

      <template v-if="startTime && selectedDate">
        <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-4" style="line-height: 1.4">
          <div class="p-mb-3">
            <div class="p-mb-3"></div>
            <b>{{ $t('Approximate service time') }}: </b>
            <span>{{ formatDecimal(offerTime + (serviceAdditionalTimeInMinutes / 100)) }} {{ $t('h') }}.</span>
          </div>
          <div class="p-mb-3">
            <b>{{ $t('Booking time') }}: </b>
            <span v-if="startTime">{{ formatDate(startTime / 1000) }}</span>
          </div>
          <div class="p-mb-3">
            <b>{{ $t('Approximate order end time') }}: </b>
            <span v-if="endTime">{{ formatDate(endTime / 1000) }}</span>
            <span v-else class="warning-color">{{ $t('workTakesMoreTimeText') }}</span>
          </div>
          <!--        <div class="p-mt-4" >-->
          <!--          <Checkbox v-model="agreeCheckbox" id="agree_checkbox" :binary="true" class="p-mr-1"/>-->
          <!--          <span :class="{'p-invalid' : submitted && !agreeCheckbox}" id="agree_checkbox">Olen nõus, et tööde valmimise aeg on liigikaudne, sõltuvalt tellimusse töömahust. Töö käigus võib tekkida vajadus lisatöödeks.</span>-->
          <!--        </div>-->
        </div>

        <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-5">
          <div class="p-float-label p-input-icon-right" >
            <i class="ti-comment-alt"></i>
            <Textarea id="additional_customer_wishes"
                      :class="{'p-invalid' : computeCustomerCommentRemainingLength < 0}"
                      v-model="additionalCustomerWishes"
                      :rows="$store.state.mobileLayout ? 4: 3"
                      :autoResize="true"
                      autocomplete="off"/>
            <label for="additional_customer_wishes">{{ $t('Additional wishes (optional)') }}</label>
          </div>
          <div style="font-size: 0.9rem" :class="{'warning-color': computeCustomerCommentRemainingLength < 0}">{{ $t('Characters left') }}: <span>{{ computeCustomerCommentRemainingLength }}</span></div>
        </div>

        <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12"
             :class="{'p-invalid' : submitted && !agreeCheckbox}">
          <div class="p-d-flex p-ai-center">
            <Checkbox v-model="agreeCheckbox" :binary="true" class="p-mr-2"/>
            <div @click="toggleAgreeCheckbox"
                 class="pointer"
                 :class="{'p-invalid' : submitted && !agreeCheckbox}">
              {{ $t('bookingAgreementWithTermsText') }}
            </div>
          </div>
        </div>
      </template>

    </div>

    <template #footer>
      <Button :label="$t('Cancel')" icon="pi pi-times" class="p-button-text" @click="close"/>
      <Button :label="$t('Confirm')"
              icon="pi pi-check"
              class="p-button-text"
              :disabled="dataIsSending || disableSaveButton || !selectedDate || !startTime"
              @click="bookAppointment"/>
    </template>
  </Modal>
</template>
<script>
import httpMixins from "@/mixins/httpMixins";
import formatMixins from "@/mixins/formatMixins";
import settings from "@/settings";
import httpClient from "@/services/http.services";
// import SmsMessages from "@/translations/SmsMessages";
// import socketMixins from "@/mixins/socketMixins";
import webSocketMixins from "@/mixins/webSocketMixins";
import generateMixins from "@/mixins/generateMixins";
import overlayVisibilityMixins from "@/mixins/overlayVisibilityMixins";

export default {
  mixins: [ formatMixins, httpMixins, webSocketMixins, generateMixins, overlayVisibilityMixins ],
  emits: ['close', 'change-offer-state'],
  name: 'AppointmentBookingModal',
  props: {
    item: Object,
    visible: Boolean,
    useAppointmentBooking: {
      type: Boolean,
      default: false
    },
    nonBookingDays: Array,
    appointmentFirstDate: Number,
    appointmentLastDate: Number,
    bookingHours: Object,
    workingHours: Object,
    bookingStep: Number,
    offerTime: Number,
    offerId: Number,
    thereIsActiveServicePosition: {
      type: Boolean,
      default: false
    },
    offerData: Object,
  },
  data() {
    return {
      dataIsSending: false,
      // maxAdditionalCustomerWishesLength: 395,
      sendNotificationAt: null,
      sendNotificationDue: null,
      notificationSmsText: null,
      workingStartTime: null,
      workingEndTime: null,
      workingLastTimeStamp: null,
      additionalCustomerWishes: null,
      agreeCheckbox: false,
      appointmentTimes: null,
      // invalidDates: ['1682715600000'],
      // invalidDates: [],
      selectedDate: null,
      dateIsValid: true,
      disableSaveButton: false,
      submitted: false,
      startTime: null,
      endTime: null,
      serviceAdditionalTimeInMinutes: 10,
      // minDateValue: new Date(),
      // maxDateValue: null,
      settings
    }
  },
  watch: {
    visible() {
      if (!this.visible) {
        this.submitted = false
        this.selectedDate = null
        this.dateIsValid = true
        this.appointmentTimes = null
        this.startTime = null
        this.endTime = null
        this.workingStartTime = null
        this.workingEndTime = null
        this.agreeCheckbox = false
        this.additionalCustomerWishes = null
        this.notificationSmsText = null
        this.sendNotificationAt = null
        this.sendNotificationDue = null
      }
    },
  },
  methods: {
    toggleAgreeCheckbox() {
      this.agreeCheckbox = !this.agreeCheckbox
    },
    selectTime(time) {
      const offerTimeInMilliseconds = this.offerTime * 60 * 60 * 1000
      const serviceAdditionalTimeInMilliseconds = this.serviceAdditionalTimeInMinutes * 60 * 1000
      // console.log(time, this.offerTime)
      const startTime = +new Date(time)
      const endTime = +new Date(time + offerTimeInMilliseconds + serviceAdditionalTimeInMilliseconds)

      this.startTime = startTime
      this.endTime = endTime

      if (endTime > this.workingLastTimeStamp) {
        this.endTime = null
      }
    },
    selectDate(value) {
      this.checkDateIsValid()
      if (!this.dateIsValid || !this.selectedDate) {
        return false
      }

      this.startTime = null
      this.endTime = null

      const nonBookingDays = []
      nonBookingDays.push(+new Date(this.selectedDate))
      // const json = JSON.stringify(nonBookingDays)
      // console.log(JSON.stringify(json))

      this.generateTimes(value)
    },
    generateTimes(date) {
      const weekDay = date.getDay()
      let weekDayLetters

      switch(weekDay) {
        case 0: { weekDayLetters = 'Su'; break }
        case 1: { weekDayLetters = 'Mo'; break }
        case 2: { weekDayLetters = 'Tu'; break }
        case 3: { weekDayLetters = 'We'; break }
        case 4: { weekDayLetters = 'Th'; break }
        case 5: { weekDayLetters = 'Fr'; break }
        case 6: { weekDayLetters = 'Sa'; break }
      }


      const bookingHours = this.bookingHours[weekDayLetters]
      const workingHours = this.workingHours[weekDayLetters]
      if (!bookingHours || !workingHours || !this.offerTime) {
        return
      }

      const splittedWorkingHours = workingHours.split('-')
      const workingStartTime = splittedWorkingHours[0]
      this.workingStartTime = workingStartTime
      const workingEndTime = splittedWorkingHours[1]
      this.workingEndTime = workingEndTime
      const workingEndHours = +workingEndTime.split('.')[0]
      const workingEndMinutes = +workingEndTime.split('.')[1]

      const splittedBookingHours = bookingHours.split('-')
      const bookingStartTime = splittedBookingHours[0]
      const bookingStartHours = +bookingStartTime.split('.')[0]
      const bookingStartMinutes = +bookingStartTime.split('.')[1]

      const bookingEndTime = splittedBookingHours[1]
      const bookingEndHours = +bookingEndTime.split('.')[0]
      const bookingEndMinutes = +bookingEndTime.split('.')[1]

      const selectedDate = date;
      const selectedDateYear = selectedDate.getFullYear()
      const selectedDateDay = selectedDate.getDate()
      const selectedDateMonth = selectedDate.getMonth()

      const firstBookingTimestamp = +new Date(selectedDateYear, selectedDateMonth, selectedDateDay, bookingStartHours, bookingStartMinutes)
      const lastBookingTimestamp = +new Date(selectedDateYear, selectedDateMonth, selectedDateDay, bookingEndHours, bookingEndMinutes)

      const workingLastTimestamp = +new Date(selectedDateYear, selectedDateMonth, selectedDateDay, workingEndHours, workingEndMinutes)
      this.workingLastTimeStamp = workingLastTimestamp

      // this.workingLastTimeStamp = lastTimestamp
      //No need Don't delete!
      // const dayTotalWorkTimeInMilliseconds = +lastTimeStamp - +firstTimeStamp
      // const dayTotalWorkTimeInSeconds = dayTotalWorkTimeInMilliseconds / 1000
      // const dayTotalWorkTimeInHours = dayTotalWorkTimeInSeconds / 60 / 60
      //
      const appointmentTimes = [firstBookingTimestamp]

      let nextTimestamp = firstBookingTimestamp

      const lastTimestamp = workingLastTimestamp < lastBookingTimestamp ? workingLastTimestamp : lastBookingTimestamp

      for (let i = 0; nextTimestamp < lastTimestamp; ++i) {
        const bookingStep = this.bookingStep ?? 15
        const bookingStepInMilliseconds = bookingStep * 60 *1000
        nextTimestamp += bookingStepInMilliseconds
        if (nextTimestamp < lastTimestamp + 1) {
          appointmentTimes.push(nextTimestamp)
        }

      }

      // console.log(firstBookingTimestamp)

      this.appointmentTimes = appointmentTimes
    },
    generateHoursStringFromTimestamp(timestamp) {
      if (!timestamp) return false

      const date = new Date(timestamp)

      const hours = `${date.getHours()}`
      let minutes = `${date.getMinutes()}`
      if (+minutes === 0) {
        minutes += '0'
      } else if (minutes.length === 1) {
        minutes = '0' + minutes
      }

      return `${hours}:${minutes}`
    },
    checkDateIsValid() {
      const date = this.selectedDate
      if (!date) return

      this.dateIsValid = date instanceof Date && !isNaN(date)

      if (!this.dateIsValid) {
        this.startTime = null
        this.appointmentTimes = null
      }
    },
    async bookAppointment() {
      this.submitted = true
      if (!this.selectedDate ||
          !this.dateIsValid ||
          !this.agreeCheckbox ||
          !this.startTime ||
          this.computeCustomerCommentRemainingLength < 0) {
        return false
      }

      this.dataIsSending = true
      this.disableSaveButton = true

      const bookingData = {
        offer_id: this.offerId,
        start_time: this.startTime ? +new Date(this.startTime / 1000) : null,
        additional_customer_wishes: this.additionalCustomerWishes,
        generate_task_order: this.thereIsActiveServicePosition,
      }

      // if (settings.useSmsService) {
      //   this.generateSendNotificationAtTime()
      //   this.generateSmsNotificationText()
      //   if (this.notificationSmsText && this.sendNotificationAt && !settings.isDevMode) {
      //     bookingData.send_notification_at = +new Date(this.sendNotificationAt / 1000)
      //     bookingData.send_notification_due = +new Date(this.sendNotificationDue / 1000)
      //     bookingData.notification_sms_text = this.notificationSmsText
      //   }
      // }

      if (settings.useSmsService && this.startTime) {
        const startDate = new Date(this.startTime)
        this.generateSendNotificationAtTime(startDate)
        this.generateSmsNotificationText(startDate)
        // if (this.notificationSmsText && this.sendNotificationAt && !settings.isDevMode) {
        if (this.notificationSmsText && this.sendNotificationAt && this.sendNotificationDue) {
          bookingData.send_notification_at = +new Date(this.sendNotificationAt / 1000)
          bookingData.send_notification_due = +new Date(this.sendNotificationDue / 1000)
          bookingData.notification_sms_text = this.notificationSmsText
        }
      }

      try {
        const { status, data } = await httpClient.post('site/book-appointment', bookingData)
        if (status === 201 && data?.success) {
          this.close()
          this.$emit('change-offer-state', bookingData.start_time)
          // this.sendInstantSocket('createOrder', data.id)
          this.sendAppointmentBookedSocket(data.offer_id)
        } else if (data?.error) {
          this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: this.settings.toastLife});
          // this.$toast.add({severity:'error', summary: this.$t('Error'), life: settings.toastLife});
        }
      } catch(err) {
        const errorMessage = err?.message ?? 'Unknown error'
        this.$toast.add({severity:'error', summary: this.$t('Error'), detail: errorMessage, life: settings.toastLife});
        // this.showError(err)
      } finally {
        this.dataIsSending = false
        this.disableSaveButton = false
      }
    },
    // generateSendNotificationAtTime() {
    //   if (!this.startTime || !this.dateIsValid) {
    //     return false
    //   }
    //
    //   const orderStartDate = new Date(this.startTime)
    //
    //   const now = new Date()
    //   const nowYear = now.getFullYear()
    //   const nowMonth = now.getMonth()
    //   const nowDay = now.getDate()
    //
    //   const orderStartYear = orderStartDate.getFullYear()
    //   const orderStartMonth = orderStartDate.getMonth()
    //   const orderStartDay = orderStartDate.getDate()
    //   let orderStartHours = orderStartDate.getHours()
    //   let orderStartMinutes = orderStartDate.getMinutes()
    //
    //   if (orderStartYear < nowYear ||
    //       orderStartYear === nowYear && orderStartMonth < nowMonth ||
    //       orderStartYear === nowYear && orderStartMonth === nowMonth && orderStartDay <= nowDay) {
    //     this.sendNotificationAt = null
    //     this.sendNotificationDue = null
    //   } else {
    //     console.log(orderStartHours < settings.minTimeToSendSms.hours, orderStartHours > settings.maxTimeToSendSms.hours)
    //     if (orderStartHours < settings.minTimeToSendSms.hours) {
    //       orderStartHours = settings.minTimeToSendSms.hours
    //       orderStartMinutes = 0
    //     } else if (orderStartHours > settings.maxTimeToSendSms.hours) {
    //       orderStartHours = settings.maxTimeToSendSms.hours
    //       orderStartMinutes = 0
    //     }
    //     this.sendNotificationAt = new Date(orderStartYear, orderStartMonth, orderStartDay - 1, orderStartHours, orderStartMinutes)
    //     this.sendNotificationDue = new Date(orderStartYear, orderStartMonth, orderStartDay - 1, settings.maxTimeToSendSms.hours, settings.maxTimeToSendSms.minutes)
    //   }
    // },
    // generateSmsNotificationText() {
    //   if (!this.startTime || !this.sendNotificationAt) return false
    //
    //   // const orderStartDate = new Date(this.startTime)
    //   // const orderStartYear = orderStartDate.getFullYear()
    //   // let orderStartMonth = orderStartDate.getMonth()
    //   // let orderStartDay = orderStartDate.getDate()
    //
    //   const notification_sms_text = SmsMessages.appointmentReminder[this.$i18n.locale]?.text ?? SmsMessages.appointmentReminder[settings.defaultLanguage]?.text
    //
    //   // if (notification_sms_text) {
    //   //   if (orderStartDay?.toString().length === 1) {
    //   //     orderStartDay = '0' + '' + orderStartDay
    //   //   }
    //   //   if (orderStartMonth?.toString().length === 1) {
    //   //     orderStartMonth = '0' + '' + (orderStartMonth + 1)
    //   //   }
    //   //
    //   //   let hours = orderStartDate.getHours()
    //   //   let minutes = orderStartDate.getMinutes()
    //   //
    //   //   if (hours?.toString().length === 1) {
    //   //     hours = '0' + '' + hours
    //   //   }
    //   //   if (minutes?.toString().length === 1) {
    //   //     minutes = '0' + '' + minutes
    //   //   }
    //   //
    //   //   const searchDate = '{date}'
    //   //   const searchTime = '{time}'
    //   //   const replaceDateWith = `${orderStartDay}.${orderStartMonth}.${orderStartYear}`
    //   //   const replaceTimeWith = `${hours}:${minutes}`
    //   //
    //   //   let result = notification_sms_text.split(searchDate).join(replaceDateWith).split(searchTime).join(replaceTimeWith)
    //
    //   if (notification_sms_text) {
    //     const orderStartDate = this.startTime
    //     const orderStartYear = orderStartDate.getFullYear()
    //     let orderStartMonth = orderStartDate.getMonth()
    //     let orderStartDay = orderStartDate.getDate()
    //
    //     let hours = orderStartDate.getHours()
    //     let minutes = orderStartDate.getMinutes()
    //     const dateTimeStamp = +new Date(orderStartYear, orderStartMonth, orderStartDay, hours, minutes)
    //     const date = this.formatDay(dateTimeStamp / 1000)
    //     const time = this.formatTime(dateTimeStamp / 1000)
    //
    //     const searchDate = '{date}'
    //     const searchTime = '{time}'
    //     const replaceDateWith = date
    //     const replaceTimeWith = time
    //
    //     let result = notification_sms_text.split(searchDate).join(replaceDateWith).split(searchTime).join(replaceTimeWith)
    //
    //     this.notificationSmsText = result
    //   }
    //
    //     // this.notificationSmsText = result
    //   // }
    // },
    close() {
      this.$emit('close')
      this.submitted = false
    },
  },
  computed: {
    maxAdditionalCustomerWishesLength() {
      const customerCommentLength = this.offerData?.customer_comment?.length || 0
      // if (customerCommentLength)
      return 2000 - customerCommentLength - 3  // 3 is for ' + '
    },
    computeCustomerCommentRemainingLength() {
      // if (!this.description) return 0
      return this.maxAdditionalCustomerWishesLength - (this.additionalCustomerWishes?.length || 0)
    },
    computedDisabledWeekDays() {
      if (!this.bookingHours) return [6, 0]

      const disabledDays = []

      if (!this.bookingHours['Mo']) disabledDays.push(1)
      if (!this.bookingHours['Tu']) disabledDays.push(2)
      if (!this.bookingHours['We']) disabledDays.push(3)
      if (!this.bookingHours['Th']) disabledDays.push(4)
      if (!this.bookingHours['Fr']) disabledDays.push(5)
      if (!this.bookingHours['Sa']) disabledDays.push(6)
      if (!this.bookingHours['Su']) disabledDays.push(0)

      return disabledDays
    },
    computedMinDateValue() {
      const now = new Date();
      const nowYear = now.getFullYear()
      const nowDay = now.getDate()
      const nowMonth = now.getMonth()

      let appointmentFirstDate = this.appointmentFirstDate || this.appointmentFirstDate === 0 ? +this.appointmentFirstDate : 2
      let i = 0;
      for (i; i < appointmentFirstDate; i++) {
        const date = new Date(nowYear, nowMonth, nowDay + i + 1)
        const dateWeekDay = date.getDay()
        const indexOfDisabledWeekDays = this.computedDisabledWeekDays.indexOf(dateWeekDay)
        const indexOfDisabledDates = this.nonBookingDays?.indexOf(+date)
        if (indexOfDisabledWeekDays !== -1 || indexOfDisabledDates !== -1) {
          appointmentFirstDate++
        }

        // console.log()
        // if (this.computedDisabledWeekDays.length) {
        //   this.computedDisabledWeekDays.forEach(weekDay => {
        //     if (dateWeekDay === weekDay) {
        //       appointmentFirstDate++
        //     }
        //   })
        // }

        // if (this.computedDisabledDates.length) {
        //   this.computedDisabledDates.forEach(disabledDate => {
        //     if (+disabledDate === +date) {
        //       appointmentFirstDate++
        //     }
        //   })
        // }
      }

      const firstDate = new Date(nowYear , nowMonth, nowDay + i)
      return firstDate
    },
    computedMaxDateValue() {
      const now = new Date();
      const nowYear = now.getFullYear()
      const nowDay = now.getDate()
      const nowMonth = now.getMonth()

      const appointmentLastDate = this.appointmentLastDate || this.appointmentLastDate === 0 ? +this.appointmentLastDate : 60
      return new Date(nowYear , nowMonth, nowDay + appointmentLastDate)
    },
    computedDisabledDates() {
      if (!this.nonBookingDays?.length) return []

      const invalidDates = []
      this.nonBookingDays.forEach(day => {
        invalidDates.push(new Date(day))
      })

      return invalidDates
    },
    // computedWindowSize() {
    //   if (this.$store.state.mobileLayout) {
    //     return {position: 'absolute', top: 0, width: '95%', marginTop: '10px'}
    //   } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
    //     return {position: 'absolute', top: 0, width: '630px', marginTop: '15px'}
    //   } else {
    //     return {position: 'absolute', top: 0, width: '980px'}
    //   }
    // },
    computedWindowSize() {
      if (this.$store.state.mobileLayout) {
        return {position: 'absolute', top: 0, width: '95%', marginTop: '10px'}
      } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {position: 'absolute', top: 0, width: '550px', marginTop: '15px'}
      } else {
        return {position: 'absolute', top: 0, width: '550px'}
      }
    },
  },

}
</script>

<!--<style scoped lang="scss">-->
<!--</style>-->