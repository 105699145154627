<template >
  <Toast/>
	<div class="login-body login-background" @click="onDocumentClick">
		<div class="body-container">

      <div class="header">
<!--        <div class="p-d-flex p-jc-between p-ai-center">-->
<!--          <img class="logo" src="assets/layout/images/logo_small.png" />-->
<!--          <span class="logo-text">VirtualGarage</span>-->
<!--        </div>-->
        <div class="logo-wrapper">
          <img class="logo-img" src="/assets/images/logo.png" alt="">
        </div>
        <div>
          <LoginLanguageSelector
            @languageMenubuttonClick="onLanguageMenuButtonClick"
            :languageMenuActive="languageMenuActive"/>
        </div>
      </div>

      <div class="login-wrapper">
        <div class="login-container card">
          <h1>{{ $t('Welcome') }}</h1>
          <p>{{  $t('Sign in to start your session') }}</p>
          <div class="p-grid p-fluid login-fieldset">

            <div class="p-col-12">
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                    <i class="pi pi-user"></i>
                </span>
                <InputText :class="{'p-invalid' : submitted && !formData.login}" v-model.trim="formData.login" :placeholder="$t('Email')" required="true"/>
              </div>
              <div class="input-error-wrapper">
                <small class="p-invalid" v-if="submitted && !formData.login">{{ $t('Required field') }}</small>
              </div>
<!--              <div class="p-float-label p-input-icon-right">
                <i class="pi pi-user" />
                <InputText id="email" autocomplete="off"/>
                <label for="email">{{ $t('Email') }}</label>
              </div>-->
            </div>

            <div class="p-col-12">
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                    <i class="pi pi-lock"></i>
                </span>
                <InputText :class="{'p-invalid' : submitted && !formData.password}" v-model.trim="formData.password" type="password" :placeholder="$t('Password')" required="true"/>
              </div>
              <div class="input-error-wrapper">
                <small class="p-invalid" v-if="submitted && !formData.password">{{ $t('Required field') }}</small>
              </div>
<!--              <div class="p-float-label p-input-icon-right">-->
<!--                <i class="pi pi-lock" />-->
<!--                <InputText id="email" autocomplete="off"/>-->
<!--                <label for="email">{{ $t('Password') }}</label>-->
<!--              </div>-->
            </div>

<!--            <div class="p-col-12">-->
<!--              <Button :label="$t('Sign In')"-->
<!--                      icon="pi pi-check"-->
<!--                      :disabled="loginButtonDisabled"-->
<!--                      @click="login"/>-->
<!--            </div>-->

            <div class="p-col-12 p-mb-3">
              <Button :label="$t('Sign In')"
                      style="width: auto;"
                      icon="pi pi-check"
                      :disabled="loginButtonDisabled"
                      @click="login"/>
            </div>

<!--            <div class="p-col-6 password-container">-->
<!--              <router-link to="/forgot-password" class="p-link">{{ $t('Forgot Password?') }}</router-link>-->
<!--              <router-link to="#" class="p-link">{{ $t('Forgot Password?') }}</router-link>-->
<!--            </div>-->

<!--            <div class="p-col-12">-->
<!--              <hr>-->
<!--              {{ $t('New on our platform?') }}-->
<!--              <span>-->
<!--                <router-link to="/register" class="p-link">{{ $t('Create an account') }}</router-link>-->
<!--              </span>-->
<!--            </div>-->
          </div>

          <hr>
          <div class="login-container-copyright">©{{ computeYear }}
<!--            <b><a href="https://vgsoft.eu/" target="_blank"> VirtualGarage.app</a></b>.-->
<!--            <b> CARVEX OÜ</b>.-->
            CARVEX OÜ.
            {{ $t('All Rights Reserved') }}.
          </div>
        </div>
      </div>


<!--      <div class="body-container__footer">-->
<!--        © {{ computeYear }} <b><a href="https://virtualgarage.app/" target="_blank">VirtualGarage.app</a></b>. {{ $t('All Rights Reserved') }}-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import LoginLanguageSelector from '@/components/LoginLanguageSelector'
import authService from '@/services/auth.service'
import httpMixins from "@/mixins/httpMixins";
import settings from "@/settings";

export default {
  mixins: [ httpMixins ],
  components: { LoginLanguageSelector },
  data() {
    return {
      loginButtonDisabled: false,
      submitted: false,
      formData: {
        login: null,
        password: null,
      },
      languageMenuActive: false,
      languageMenuButtonClick: false
    }
  },
  watch: {
    '$i18n.locale'() {
      this.changeTitle()
    },
  },
  mounted() {
    if (localStorage.getItem('lang')) {
      this.$i18n.locale = localStorage.getItem('lang')
    }

    if (this.$i18n.locale === settings.defaultLanguage) {
      this.changeTitle()
    }
  },
  methods: {
    changeTitle() {
      const appName = settings.appName
      // const defaultTitle = appName
      // if (this.$route.meta.title) {
      //   document.title = this.$t(this.$route.meta.title) + ' | ' + appName
      // } else {
      //   document.title = defaultTitle
      // }
      document.title = this.$t('Login') + ' | ' + appName
    },
    onLanguageMenuButtonClick() {
      this.languageMenuButtonClick = true
      this.languageMenuActive = !this.languageMenuActive
    },
    onDocumentClick() {
      if (!this.languageMenuButtonClick) {
        this.languageMenuActive = false
      }

      this.languageMenuButtonClick = false
    },
    goDashboard(){
      this.$router.push({ path: '/' });
    },
    async login() {
      this.loginButtonDisabled = true
      this.submitted = true

      if (!this.formData.login || !this.formData.password) {
        this.loginButtonDisabled = false
        return false
      }

      const { success, err, errors } = await authService.login(this.formData)
      if (success) {
        this.$router.push('/')
      } else {
        this.loginButtonDisabled = false
        if (errors?.password?.[0] === 'Incorrect login or password.') {
          this.$toast.add({severity:'error', summary: this.$t('Error'), detail: this.$t('Incorrect login or password'), life: 1750});
        } else {
          this.showError(err)
        }
      }
    }
  },
  computed: {
    computeYear() {
      return new Date().getFullYear()
    }
  },
  // mounted() {
  //   localStorage.setItem('theme', 'light-blue')
  //   console.log(localStorage.getItem('theme'))
  //   EventBus.emit('on-schema', 'light');
  // }
}
</script>

<style scoped lang="scss">
.body-container {
  //display: flex;
  //flex-flow: column;
  //align-items: center;
  //justify-content: space-between;
  //height: 100vh;
  .header {
    margin: 2vh auto;
    width: 90vw;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo-wrapper {
      margin-right: 10px;
      .logo-img {
        display: block;
        max-height: 60px;
        max-width: 270px;
        width: 100%;
        height: 100%;
      }
    }
  }
  .login-wrapper {
    margin-top: 12vh;
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    .login-container {
      width: auto;
      max-width: 470px;
      text-align: center;
      padding: 30px 20px;
    }
  }

  .body-container__footer {
    width: 100%;
    padding: 10px;
    font-size: 0.9em;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.15);
    color: #fff;
  }
}

.login-container-copyright {
  margin-top: 20px;
  font-size: 0.85em;
}

.input-error-wrapper {
  text-align: left;
}
//.errors {
//  margin: 12px auto 0;
//  //padding: 2px 0;
//  //width: 300px;
//  //background-color: transparentize(red, 0.5);
//  //color: white;
//  color: orangered;
//}
//
//.errors-message {
//  padding: 6px 0;
//}
//
//.login-btn:disabled {
//  background-color: red;
//}

</style>
