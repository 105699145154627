'use strict'
import settings from "@/settings";

const showErrorsMixins = {
    methods: {
        showError(err) {
            if (!err) return false
            // console.log(err.response?.data)
            const errorName = err.response?.data?.name ? err.response.data.name + ':' : ''
            const errorMessage = err.response?.data?.message ?? err ?? 'Unknown error'
            // Database Exception?
            if (this.$toast) {
                const isDatabaseException = errorName.includes('Database Exception')
                if (isDatabaseException) {
                    const numberOutOfRange = errorMessage.includes('Numeric value out of range')
                    if (numberOutOfRange) {
                        this.$toast.add({severity:'error', summary: this.$t('Error'), detail: 'Numeric value out of range', life: settings.toastLife})
                    } else {
                        this.$toast.add({severity:'error', summary: this.$t('Error'), detail: 'Database Exception', life: settings.toastLife})
                    }
                } else {
                    const isServerConnectionError = errorMessage.includes('Server connection error')
                    // const isServerConnectionError = errorMessage.includes('Server connection error')
                    // console.log(isServerError)
                    if (!isServerConnectionError) {
                        this.$toast.add({severity:'error', summary: `${errorName}`, detail: `${errorMessage}`, life: settings.toastLife})
                    }
                }
                // this.$toast.add({position: 'bottom-center',severity:'error', summary: `${errorName}`, detail: `${errorMessage}`, life: settings.toastLife})
            }
            console.log(`${errorName} ${errorMessage}`)
        },
        // showFinancialPeriodIsClosedError() {
        //     this.$toast.add({severity:'warn', detail: 'Financial period already closed', life: this.settings.toastLife});
        // },
        showUnknownError() {
            this.$toast.add({severity:'error', summary: this.$t('Error'), detail: this.$t('Unknown error'), life: settings.toastLife})
        },
        showFileNotFoundError() {
            this.$toast.add({severity:'error', summary: `404`, detail: 'File not found', life: settings.toastLife})
        }
    }
}

export default showErrorsMixins
