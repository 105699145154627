<template>
  <Modal :visible="visible"
         :style="computedWindowSize"
         class="p-fluid"
         @close="close">
    <template v-slot:header>
      <div class="p-dialog-title">{{ $t('Confirm') }}</div>
    </template>
    <Spinner v-if="showSpinner" :isSmall="true" :spinnerStyle="'height:60px'"/>
    <div v-else class="p-formgrid p-grid">
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12">
        <div class="p-d-flex p-ai-center">
          <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
          <span>{{ $t('Are you sure you want to delete selected items?') }}</span>
        </div>
      </div>
    </div>
    <template #footer>
      <Button :label="$t('No')" :disabled="showSpinner" icon="pi pi-times" class="p-button-text" @click="close"/>
      <Button :label="$t('Yes')" :disabled="showSpinner" icon="pi pi-check" class="p-button-text" @click="deleteItem" />
    </template>

  </Modal>
</template>

<script>
export default {
  name: 'ConfirmMultipleDeleteModal',
  emits: ['close', 'delete-items'],
  props: {
    header: null,
    footer: null,
    visible: Boolean,
    style: Object,
    showSpinner: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    close() {
      this.$emit('close')
      this.submitted = false
    },
    deleteItem() {
      this.$emit('delete-items')
    }
  },
  computed: {
    // computedWindowSize() {
    //   if (this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
    //     return {position: 'relative', top: '-10vh', width: '85%', marginTop: '10px'}
    //   } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
    //     return {position: 'relative', top: '-10vh', width: '450px', marginTop: '25px'}
    //   } else {
    //     return {position: 'relative', top: '-10vh', width: '450px', marginTop: '50px'}
    //   }
    // }
    computedWindowSize() {
      if (this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {width: '85%'}
      } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {width: '450px'}
      } else {
        return {width: '450px'}
      }
    }
  }
}
</script>