<template>
  <Modal :visible="visible"
         :style="computedWindowSize"
         class="p-fluid"
         @close="close">
    <template v-slot:header>
      <div class="p-dialog-title">{{ $t('Confirm') }}</div>
    </template>
    <Spinner v-if="modalDataIsLoading" :isSmall="true" :spinnerStyle="'height:60px'"/>
    <div v-else class="p-formgrid p-grid">
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12">
        <div class="p-d-flex p-ai-center">
          <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
          <span v-if="data">{{ $t('Are you sure you want to delete') }} <b>{{data}}</b>?</span>
          <span v-else>{{ $t('Are you sure you want to delete selected item?') }}</span>
        </div>
      </div>
    </div>
    <template #footer>
      <Button :label="$t('No')" icon="pi pi-times" :disabled="modalDataIsLoading" class="p-button-text" @click="close"/>
      <Button :label="$t('Yes')" icon="pi pi-check" :disabled="modalDataIsLoading" class="p-button-text" @click="deleteItem" />
    </template>
  </Modal>
</template>

<script>
// import Spinner from "@/components/Spinner";
export default {
  // components: { Spinner },
  name: 'ConfirmDeleteModal',
  emits: ['close', 'delete-item'],
  props: {
    data: {
      type: [String, null],
      default: null
    },
    header: null,
    footer: null,
    visible: Boolean,
    style: Object,
    modalDataIsLoading: {
      type: Boolean,
      default: false
    }
  },
  // watch: {
  //   visible(boolean) {
  //     // console.log(boolean)
  //     if (boolean) {
  //       this.$store.commit('toggleFirstLayer', true)
  //     } else {
  //       this.$store.commit('toggleFirstLayer', false)
  //       this.submitted = false
  //     }
  //   },
  //   '$store.state.firstLayerIsOpened'() {
  //     if (!this.$store.state.firstLayerIsOpened) {
  //       if (this.visible) {
  //         this.close()
  //       }
  //     }
  //   }
  // },
  methods: {
    close() {
      this.$emit('close')
      this.submitted = false
    },
    deleteItem() {
      this.$emit('delete-item')
    }
  },
  computed: {
    // computedWindowSize() {
    //   if (this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
    //     return {position: 'relative', top: '-10vh', width: '85%', marginTop: '10px'}
    //   } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
    //     return {position: 'relative', top: '-10vh', width: '450px', marginTop: '25px'}
    //   } else {
    //     return {position: 'relative', top: '-10vh', width: '450px', marginTop: '50px'}
    //   }
    // },
    computedWindowSize() {
      if (this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {width: '85%'}
      } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {width: '450px'}
      } else {
        return {width: '450px'}
      }
    }
  }
}
</script>