<template>
<!--  v-tooltip.top="tooltipData"-->
  <div class="customer-status-wrapper"
       :class="{'changeable':isChangeable}"
       @contextmenu="onCustomerStatusContextClick($event)">
    <div v-if="customerStatus" class="customer-status-circle" :class="CustomerStatuses.find(s => s.id === customerStatus.status_id)?.color"></div>
    <div v-else class="customer-status-circle"></div>
    <CustomerStatusContextMenu
        v-if="isChangeable"
        @leaf-click="changeCustomerStatusOnClick"
        ref="customerStatusesMenuAccordion"
        :customerHaveStatusesHistory="!!customerStatus"
        :model="CustomerStatuses"/>
  </div>

  <ChangeCustomerStatusModal :visible="changeCustomerStatusModal"
                             :customerId="customerId"
                             :newStatus="newCustomerStatus"
                             @change-customer-status="changeCustomerStatus"
                             @close="closeChangeCustomerStatusModal"/>

  <CustomerStatusHistoryModal :visible="customerStatusHistoryModal"
                              v-if="CustomerStatuses?.length"
                              :loadCustomerHistory="loadCustomerHistory"
                              @items-loaded="customerHistoryOnLoad"
                              :customerId="customerId"
                              @close="closeCustomerStatusHistoryModal"/>

<!--  <div v-else class="customer-status-wrapper">-->
<!--    <div class="customer-status-circle grey">-->
<!--      &lt;!&ndash;      {{ customerStatus.status_id }}&ndash;&gt;-->
<!--      &lt;!&ndash;      {{ CustomerStatuses.find(s => s.id === status)?.label[$i18n.locale] }}&ndash;&gt;-->
<!--    </div>-->
<!--  </div>-->


</template>

<script>
import CustomerStatuses from "@/translations/states/CustomerStatuses";
import CustomerStatusContextMenu from "@/pages/users/components/CustomerStatusContextMenu";
// import httpClient from "@/services/http.services";
import ChangeCustomerStatusModal from "@/pages/users/components/ChangeCustomerStatusModal";
import CustomerStatusHistoryModal from "@/pages/users/components/CustomerStatusHistoryModal";
// import httpMixins from "@/mixins/httpMixins";
import showErrorsMixins from "@/mixins/showErrorsMixins";

export default {
  mixins: [ showErrorsMixins ],
  components: { CustomerStatusContextMenu, ChangeCustomerStatusModal, CustomerStatusHistoryModal },
  emits: ['leaf-click', 'change-customer-status'],
  name: "CustomerStatusHistoryButton",
  props: {
    customerId: Number,
    customerStatus: [Object, null],
    isChangeable: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    '$store.state.firstLayerIsOpened'() {
      if (!this.$store.state.firstLayerIsOpened) {
        if (this.changeCustomerStatusModal) {
          this.closeChangeCustomerStatusModal()
        }
        if (this.customerStatusHistoryModal) {
          this.closeCustomerStatusHistoryModal()
        }
      }
    },
  },
  data() {
    return {
      loadCustomerHistory: false,
      isLoading: false,
      customerStatusHistoryModal: false,
      changeCustomerStatusModal: false,
      newCustomerStatus: null,
      customerStatusHistory: [],
      CustomerStatuses
    }
  },
  methods: {
    customerHistoryOnLoad() {
      this.loadCustomerHistory = false
    },
    onCustomerStatusContextClick(event) {
      if (!this.isChangeable) return false
      this.$refs.customerStatusesMenuAccordion.show(event);
    },
    // async changeCustomerStatus(newStatus, oldStatus, item) {
    // async leafClick(newStatus) {
    //   this.$emit('leaf-click', newStatus)
    // },
    closeChangeCustomerStatusModal() {
      this.changeCustomerStatusModal = false
      this.$store.commit('toggleFirstLayer', false)
    },
    closeCustomerStatusHistoryModal() {
      this.customerStatusHistoryModal = false
      this.$store.commit('toggleFirstLayer', false)
    },
    changeCustomerStatusOnClick(status) {
      if (status === null) {
        this.showStatusHistory()
        return false
      }
      this.newCustomerStatus = status
      this.changeCustomerStatusModal = true
      this.$store.commit('toggleFirstLayer', true)
      // this.customerStatus.status_id = status
      // console.log(status)
      // this.$emit('change-customer-status', this.customer.id, status)
    },
    showStatusHistory() {
      // this.getCustomerStatusHistory()
      this.customerStatusHistoryModal = true
      this.$store.commit('toggleFirstLayer', true)
      this.loadCustomerHistory = true
    },
    changeCustomerStatus(comment) {
      // this.customerStatus.status_id = status
      this.$emit('change-customer-status', this.newCustomerStatus, this.customerId, comment)
      this.closeChangeCustomerStatusModal()
    },

  }
}
</script>

<style lang="scss">
.customer-status-wrapper {
  margin-right: 3px;
  padding: 2px 2px 2px 0;
  border-radius: 2px;
  //background: yellow;
  //&:hover {
  //  opacity: 0.7;
  //}
}

.changeable:hover {
  cursor: pointer;
  opacity: 0.7;
}


.customer-status-circle {
  //display: flex;
  //justify-content: center;
  //align-items: center;
  //padding: 2px;
  height: 12px;
  width: 12px;
  border-radius: 50px;
  background-color: #ccc;
  //font-size: 0.9em;
}

//.grey {
//  background: #ccc;
//}

.red {
  background-color: #ff6464;
}
.green {
  background-color: #60c666;
}

.yellow {
  background-color: #fff700;
}
</style>