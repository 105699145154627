<template>
	<div class="login-body">
		<div class="body-container">

      <div class="welcome-block">
        <div>
          <img class="logo" src="https://autocrm.pro/img/logo.png" />
        </div>
        <div>
          <button class="p-link profile language-selector p-d-flex p-jc-between p-ai-center" @click="onTopbarMenuButtonClick">
            <div>
              <span class="flag flag-ee language-selector__flag"></span>
            </div>
            <div>Eesti keel</div>
            <span class="language-selector__arrow">
            <i class="pi pi-angle-down"></i>
          </span>
          </button>
        </div>
      </div>

      <div class="login-wrapper card">
        <div class="login-container">
          <h1>Forgot password</h1>
          <p>
            Sign in to start your session
          </p>
<!--          <span class="title">Login</span>-->
          <div class="p-grid p-fluid login-fieldset">

            <div class="p-col-12">
              <InputText placeholder="Email"/>
            </div>
            <div class="p-col-6">
              <Button label="Send new" icon="pi pi-check"/>
            </div>
            <div class="p-col-6 password-container">
              <router-link to="/login">
                <button class="p-link">Login page</button>
              </router-link>
            </div>
            <div class="p-col-12 p-mt-2">
              <hr>
              New on our platform?
              <span>
                <router-link to="/register" class="p-link">Create an account</router-link>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="body-container__footer">
        Copyright © {{ getYear }} <b>AutoCRM.pro</b>. All Rights Reserved
      </div>
    </div>
  </div>
</template>

<script>
	export default {
		methods: {
			goDashboard(){
				this.$router.push({ path: '/' });
			}
		},
    computed: {
      getYear() {
        return new Date().getFullYear()
      }
    }
	}
</script>

<style scoped lang="scss">
.welcome-block {
  margin: 0 auto;
  width: 80%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logo {
    height: 40px;
  }
}

.language-selector {
  color: #fff;
  padding: 3px 10px 3px 3px;
  margin: 20px auto;

  .language-selector__flag {
    transform: scale(0.65);
  }

  .language-selector__arrow {
    //margin-left: -6px;
    position: relative;
    top: 2px;
    margin-left: 2px;
  }
}

.body-container {

  .login-wrapper {
    margin-top: 10vh;
    //margin: 18vh auto;
    //position: relative;
    //top: 50%;
    //left: 50%;
    //transform: translate(0, -70%);
  }

  .body-container__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    font-size: 0.9em;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.15);
    color: #fff;
  }
}

.login-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 15px;
}

.login-container {
  text-align: center;
}

.login-fieldset {
  position: relative;
  left: 3px;
  margin-top: 5px;
}

</style>
