import constants from '@/constants'

const CarsRouter = [
    {
        path: '/cars',
        name: 'Cars',
        meta: {
            auth: true,
            roles: [constants.userRoles.superAdmin, constants.userRoles.admin, constants.userRoles.moderator, constants.userRoles.accountant],
            title: 'Cars'
        },
        component: () => import('@/pages/cars/Cars')
    },
    {
        path: '/cars/:id',
        name: 'Car',
        meta: {
            auth: true,
            roles: [constants.userRoles.superAdmin, constants.userRoles.admin, constants.userRoles.moderator, constants.userRoles.accountant],
            title: 'Car'
        },
        redirect: { name: 'CarOverview' },
        component: () => import('@/pages/cars/Car'),
        children: [
            {
                // UserProfile will be rendered inside User's <router-view>
                // when /user/:id/profile is matched
                path: 'overview',
                name: 'CarOverview',
                component: () => import('@/pages/cars/CarOverview')
            },
            {
                // UserProfile will be rendered inside User's <router-view>
                // when /user/:id/profile is matched
                path: 'orders',
                name: 'CarOrders',
                component: () => import('@/pages/cars/CarOrders')
            },
            {
                // UserPosts will be rendered inside User's <router-view>
                // when /user/:id/posts is matched
                path: 'offers',
                name: 'CarOffers',
                component: () => import('@/pages/cars/CarOffers')
            },
            {
                // UserPosts will be rendered inside User's <router-view>
                // when /user/:id/posts is matched
                path: 'goods',
                name: 'CarGoods',
                component: () => import('@/pages/cars/CarGoods')
            },

        ]
    },
]

export default CarsRouter