<template v-if="item">
  <div  class="p-d-flex p-flex-wrap p-ai-center">
    <div @click="toggleOfferStateOverlay($event, item.id)" class="state-badge" :class="[OfferStates.find(state => state.num === item.state)?.color || '', {'pointer':showHistory}]">{{ OfferStates.find(state => state.num === item.state)?.label[$i18n.locale] || '' }}</div>
  </div>
  <OverlayPanel :ref="'os-' + item.id" :breakpoints="{'960px': '75vw'}">
    <div v-if="item.state_history && item.state_history.length">
      <div v-for="(data, index) of [...item.state_history].sort((a, b) => b.id - a.id)" :key="index">
        <div class="p-d-flex p-ai-center p-jc-between">
          <div>
            <div class="state-badge" :class="data.new ? OfferStates.find(state => state.num === data.new)?.color : ''">{{ data.new ? OfferStates.find(state => state.num === data.new)?.label[$i18n.locale] : '-' }}</div>
          </div>
          <div class="p-ml-3">
            <span v-if="data.created_at">{{ formatDate(data.created_at) }}, </span>
            <span v-if="data.creator">
              <span v-if="data.creator.type === constants.userTypes.legal_entity && data.creator.company_name">{{ data.creator.company_name }}</span>
              <span v-else>{{ data.creator.first_name }} {{ data.creator.last_name }}</span>
            </span>
          </div>
        </div>
        <br v-if="index !== item.state_history.length - 1">
      </div>
    </div>
    <div v-else>{{ $t('No data available' )}}</div>
  </OverlayPanel>
</template>

<script>
// import OverflowOverlayPanel from '@/components/OverflowOverlayPanel'
import OfferStates from "@/translations/states/OfferStates";
import constants from "@/constants";

export default {
  // components: { OverflowOverlayPanel },
  emits: ['update-items'],
  name: 'OfferStateButton',
  props: {
    item: Object,
    showHistory: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      constants,
      OfferStates
    }
  },
  methods: {
    toggleOfferStateOverlay(event, id) {
      if (!this.showHistory || !event || !id) {
        return false
      }
      this.$refs['os-' + id.toString()].toggle(event);
    },
    formatDate(date) {
      if (date && this.$store.state.branchData && this.$store.state.branchData.timezone) {
        const event = new Date(date * 1000);
        return event.toLocaleString(this.$store.state.branchData.locale, { timeZone: this.$store.state.branchData.timezone, year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' })
      }
    },
  }
}
</script>

<style scoped lang="scss">
</style>
