import constants from '@/constants'

const superAdmin = constants.userRoles.superAdmin
const admin = constants.userRoles.admin
const moderator = constants.userRoles.moderator
const accountant = constants.userRoles.accountant
const employeeWorkshopMaster = constants.userRoles.employeeWorkshopMaster
const employeeMaster = constants.userRoles.employeeMaster

const FinanceRouter = [
    {
        path: '/sales-invoices',
        name: 'SalesInvoices',
        meta: {
            auth: true,
            roles: [superAdmin, admin, accountant],
            title: 'Sales invoices'
        },
        component: () => import('@/pages/finance/SalesInvoices')
    },
    {
        path: '/purchase-invoices',
        name: 'PurchaseInvoices',
        meta: {
            auth: true,
            roles: [superAdmin, admin, moderator, accountant],
            title: 'Purchase invoices'
        },
        component: () => import('@/pages/finance/PurchaseInvoices')
    },
    {
        path: '/cashbox-transactions',
        name: 'CashboxTransactions',
        meta: {
            auth: true,
            roles: [superAdmin, admin, moderator, accountant],
            title: 'Cashbox transactions'
        },
        component: () => import('@/pages/finance/CashboxTransactions')
    },
    {
        path: '/bank-payments',
        name: 'BankPayments',
        meta: {
            auth: true,
            roles: [superAdmin, admin, accountant],
            title: 'Bank payments'
        },
        component: () => import('@/pages/finance/BankPayments')
    },
    {
        path: '/card-payments',
        name: 'CardPayments',
        meta: {
            auth: true,
            roles: [superAdmin, admin, accountant],
            title: 'Card payments'
        },
        component: () => import('@/pages/finance/CardPayments')
    },
    {
        path: '/employee-payments',
        name: 'MasterPayments',
        meta: {
            auth: true,
            roles: [superAdmin, admin, moderator, accountant, employeeWorkshopMaster, employeeMaster],
            title: 'Payments'
        },
        component: () => import('@/pages/finance/MasterPayments')
    },
]

export default FinanceRouter