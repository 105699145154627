<template>
    <div :class="containerClass">
        <div class="layout-config-content">
            <a href="#" class="layout-config-button" @click="toggleConfigurator">
                <i class="pi pi-cog"></i>
            </a>
            <a href="#" class="layout-config-close" @click="hideConfigurator">
                <i class="pi pi-times"></i>
            </a>

            <h5 style="margin-top: 0">{{  $t('Input Style') }}</h5>
            <div class="p-formgroup-inline">
                <div class="p-field-radiobutton">
                    <RadioButton id="input_outlined" name="inputstyle" value="outlined" :modelValue="value" @update:modelValue="onChange" />
                    <label for="input_outlined">{{ $t('Outlined') }}</label>
                </div>
                <div class="p-field-radiobutton">
                    <RadioButton id="input_filled" name="inputstyle" value="filled" :modelValue="value" @update:modelValue="onChange" />
                    <label for="input_filled">{{ $t('Filled') }}</label>
                </div>
            </div>

            <h5>{{ $t('Ripple Effect') }}</h5>
            <InputSwitch :modelValue="rippleActive" @update:modelValue="onRippleChange"  />

            <h5>{{ $t('Menu Type') }}</h5>
            <div class="p-field-radiobutton">
                <RadioButton :disabled="$store.state.tabletLayout" id="static" name="layoutMode" value="static" v-model="d_layoutMode" @change="changeLayout($event, 'static')" />
                <label for="static">{{ $t('Static') }}</label>
            </div>
            <div class="p-field-radiobutton">
                <RadioButton :disabled="$store.state.tabletLayout" :class="{'no-pointer':$store.state.tabletLayout}" id="overlay" name="layoutMode" value="overlay" v-model="d_layoutMode" @change="changeLayout($event, 'overlay')" />
                <label for="overlay">{{ $t('Overlay') }}</label>
            </div>
            <div class="p-field-radiobutton">
                <RadioButton :disabled="$store.state.tabletLayout" :class="{'no-pointer':$store.state.tabletLayout}" id="horizontal" name="layoutMode" value="horizontal" v-model="d_layoutMode" @change="changeLayout($event, 'horizontal')" />
                <label for="horizontal">{{ $t('Horizontal') }}</label>
            </div>
            <div class="p-field-radiobutton">
                <RadioButton :disabled="$store.state.tabletLayout" :class="{'no-pointer':$store.state.tabletLayout}" id="slim" name="layoutMode" value="slim" v-model="d_layoutMode" @change="changeLayout($event, 'slim')" />
                <label for="slim">{{ $t('Slim') }}</label>
            </div>


            <h5>{{ $t('Color schema') }}</h5>
            <div class="p-field-radiobutton">
                <RadioButton id="light" name="color" value="light" v-model="schema" @change="onschemaChange($event, 'light')" />
                <label for="light">{{ $t('Light') }}</label>
            </div>
            <div class="p-field-radiobutton">
                <RadioButton id="dark" name="color" value="dark" v-model="schema" @change="onschemaChange($event, 'dark')" />
                <label for="dark">{{ $t('Dark') }}</label>
            </div>
            <div class="p-field-radiobutton">
                <RadioButton id="dim" name="color" value="dim" v-model="schema" @change="onschemaChange($event, 'dim')" />
                <label for="dim">{{ $t('Dim') }}</label>
            </div>

            <h5>{{ $t('Themes') }}</h5>
            <div class="layout-themes">
                <div v-for="t of themes" :key="t.name">
                    <a href="#" @click="onThemeChange($event, t)" :style="{backgroundColor:t.color}">
                        <i class="pi pi-check" v-if="theme === t.name"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EventBus from './event-bus';

export default {
    emits: ['layout-change', 'change-input-style', 'change-full-theme', 'change-ripple-settings'],
    props: {
      settings: Object,
      layoutMode: String
    },
    data() {
        return {
          active: false,
          schema: this.settings.schema,
          theme: this.settings.theme,
          themes: [
              // {title: 'Blue', name: 'blue', color: '#39a3f4'},
              {title: 'Blue', name: 'blue', color: '#00b8fc'},
              {title: 'Green', name: 'green', color: '#6ebc3b'},
              {title: 'Cyan', name: 'cyan', color: '#1989AC'},
              {title: 'Purple', name: 'purple', color: '#7E57C2'},
              {title: 'Indigo', name: 'indigo', color: '#5C6BC0'},
              {title: 'Yellow', name: 'yellow', color: '#ffc800'},
              {title: 'Orange', name: 'orange', color: '#f6a821'},
              {title: 'Pink', name: 'pink', color: '#EC407A'}
          ],
          d_layoutMode: this.layoutMode,
        }
    },
    watch: {
      $route() {
          if (this.active) {
              this.active = false;
              this.unbindOutsideClickListener();
          }
      },
      layoutMode() {
        this.d_layoutMode = this.layoutMode;
      },
      settings() {
        const themeFullName = this.settings.theme + '-' + this.settings.schema
        this.changeFullTheme(themeFullName, false)
      }
    },
    outsideClickListener: null,
    methods: {
        onChange(value) {
          this.$emit('change-input-style', value)
        },
        onRippleChange(value) {
          this.$primevue.config.ripple = value;
          this.$emit('change-ripple-settings')
        },
        changeLayout(event, layoutMode) {
          this.$store.commit('changeLayoutMode', layoutMode)
          this.$emit('layout-change', layoutMode);
        },
        onschemaChange(event, color) {
            const themeLink = document.getElementById('theme-css');
            const href = themeLink.href;
            const themeFile = href.substring(href.lastIndexOf('/') + 1, href.lastIndexOf('.'));
            const themeTokens = themeFile.split('-');
            const themeName = themeTokens[1];

            this.changeFullTheme(themeName + '-' + color);
        },
        changeFullTheme(theme, saveSettings = true) {
            this.theme = theme.split('-')[0];
            this.shema = theme.split('-')[1];

            this.$store.commit('changeAppSchema', this.shema)

            this.changeStyleSheetUrl('layout-css', theme, 'layout');
            this.changeStyleSheetUrl('theme-css', theme, 'theme');

            if (saveSettings) {
              this.$emit('change-full-theme', { theme: this.theme, schema: this.schema })
            }
        },
        changeStyleSheetUrl(id, value, prefix) {
            let element = document.getElementById(id);
            let urlTokens = element.getAttribute('href').split('/');
            urlTokens[urlTokens.length - 1] = prefix + '-' + value + '.css';
            let newURL = urlTokens.join('/');
            this.replaceLink(element, newURL);

            if (value.indexOf('dark') !== -1)
                this.schema = 'dark';
            else if(value.indexOf('dim') !== -1)
                this.schema = 'dim';
            else
                this.schema = 'light';

            EventBus.emit('on-schema', this.schema);
        },
        replaceLink(linkElement, href) {
            const id = linkElement.getAttribute('id');
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute('href', href);
            cloneLinkElement.setAttribute('id', id + '-clone');

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                cloneLinkElement.setAttribute('id', id);
            });
        },
        onThemeChange(event,theme) {
            this.theme = theme.name;
            this.changeFullTheme(this.theme + '-' + this.schema);

            event.preventDefault();
        },
        toggleConfigurator(event) {
            this.active = !this.active;
            event.preventDefault();

            if (this.active)
                this.bindOutsideClickListener();
            else
                this.unbindOutsideClickListener();
        },
        hideConfigurator(event) {
            this.active = false;
            this.unbindOutsideClickListener();
            event.preventDefault();
        },
        bindOutsideClickListener() {
            if (!this.outsideClickListener) {
                this.outsideClickListener = (event) => {
                    if (this.active && this.isOutsideClicked(event)) {
                        this.active = false;
                    }
                };
                document.addEventListener('click', this.outsideClickListener);
            }
        },
        unbindOutsideClickListener() {
            if (this.outsideClickListener) {
                document.removeEventListener('click', this.outsideClickListener);
                this.outsideClickListener = null;
            }
        },
        isOutsideClicked(event) {
            return !(this.$el.isSameNode(event.target) || this.$el.contains(event.target));
        },
    },
    computed: {
        containerClass() {
            return ['layout-config', {'layout-config-active': this.active}];
        },
        rippleActive() {
            return this.$primevue.config.ripple;
        },
        value() {
            return this.$appState.inputStyle;
        }
    }
}
</script>
