<template>
  <Toast/>
  <ScrollTop />
  <ServerSocketError v-if="socketConnectionIsTerminated"/>
	<div v-else :class="containerClass" @click="onDocumentClick">
		<AppTopBar :logoIsSmall="logoIsSmall"
               ref="appTopBar"
               :language="user.language"
               :topbarMenuActive="topbarMenuActive"
               :languageMenuActive="languageMenuActive"
               :activeTopbarItem="activeTopbarItem"
               :showSearchInput="showSearchInput"
               @menubutton-click="onMenuButtonClick"
               @topbar-menubutton-click="onTopbarMenuButtonClick"
               @toggle-search-input="toggleSearchInput"
               @language-menubutton-click="onLanguageMenuButtonClick"
                @logout="logout">
    </AppTopBar>
    <transition name="layout-menu-container">
        <div class="layout-menu-container" @click="onMenuClick" v-show="isMenuVisible()">
            <div class="layout-menu-content">
                <div class="layout-menu-title">MENU</div>
                <AppMenu :key="'appmenu'"
                         :model="computedMenu"
                         :layoutMode="$store.state.layoutMode"
                         :active="menuActive"
                         @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick">
                </AppMenu>

            </div>
        </div>
    </transition>

    <div class="layout-content">
<!--          <Button class="p-mt-1 p-button-warning" @click="check">Check</Button>-->
<!--            <AppBreadcrumb></AppBreadcrumb>-->

        <div class="layout-content-container">
<!--          <div class="p-mb-3" v-if="settings.isDevMode">-->
<!--            Socket text: <InputText v-model="socketMessage" class="p-mr-2"></InputText>-->
<!--            <Button label="Send socket" @click="sendSocketText"></Button>-->
<!--          </div>-->
<!--          <div v-if="settings.isDevMode">-->
<!--            Server is connected: {{ !!this.$store.state.webSocket?.readyState }}-->
<!--            {{ this.$store.state.webSocket?.readyState }}-->
<!--          </div>-->
<!--              <router-view-->
<!--                  :user="user">-->
<!--              </router-view>-->

            <router-view v-slot="{ Component }">
              <transition name="fade" mode="out-in">
                <component :is="Component"/>
              </transition>
            </router-view>

            <LogoutCountdownModal :visible="$store.state.logoutCountdownModal"
                                  :logoutCountdownTimer="logoutCountdownTimer"
                                  @continue-session="cancelLogoutCountdownOnClick"
                                  @endSession="endSession"/>
        </div>

        <AppFooter />

        <div v-if="staticMenuMobileActive" class="layout-mask"></div>
    </div>

    <AppConfig
        :settings="user.settings"
        :layoutMode="$store.state.layoutMode"
        @layout-change="onLayoutChange"
        @change-full-theme="changeFullTheme"
        @change-ripple-settings="changeRippleSettings"
        @change-input-style="changeInputStyle"/>
	</div>
</template>
<script>
import AppTopBar from './AppTopbar.vue';
import AppMenu from './AppMenu.vue';
// import AppBreadcrumb from './AppBreadcrumb.vue';
import AppFooter from './AppFooter.vue';
import AppConfig from './AppConfig.vue';
import EventBus from './event-bus';
import menu from './translations/menu'
import authService from '@/services/auth.service'
import httpClient from "@/services/http.services";
import httpMixins from "@/mixins/httpMixins";
import settings from "@/settings";
import constants from "@/constants";
// import router from "@/router";
// import socketMixins from "@/mixins/socketMixins";
import ServerSocketError from "@/pages/ServerSocketError";
import LogoutCountdownModal from "@/components/LogoutCountdownModal";
import webSocketMixins from "@/mixins/webSocketMixins";

export default {
  mixins: [ httpMixins, webSocketMixins ],
  components: {
    'AppTopBar': AppTopBar,
    'AppMenu': AppMenu,
    // 'AppBreadcrumb': AppBreadcrumb,
    'AppFooter': AppFooter,
    'AppConfig': AppConfig,
    ServerSocketError,
    LogoutCountdownModal
  },
	data() {
		return {
      // get token() {
      //   return localStorage.getItem('lastAction') || 0;
      // },
      // set token(value) {
      //   localStorage.setItem('lastAction', value);
      // },
      logoutCountdownSeconds: 60,
      logoutCountdownTimer: 60,
      logoutCountdownInterval: null,
      logoutCountdownModal: false,
      checkLastActivityInterval: null,
      socketConnectionIsTerminated: false,
      socketConnectionError: false,
      socketIsConnected: false,
      // socketPingInterval: null,
      // webSocket: null,
      socketMessage: '',
      user: {
        settings: {
          ripple: true,
          inputStyle: 'outlined',
          layoutMode: 'horizontal',
          schema: 'light',
          theme: 'blue'
        }
      },
      originalLayoutMode: '',
      actualLayoutMode: '',
      changedLayoutToStatic: false,
      logoIsSmall: false,
      showSearchInput: false,
			staticMenuDesktopInactive: false,
			staticMenuMobileActive: false,
			overlayMenuActive: false,
			topbarMenuActive: false,
			activeTopbarItem: null,
      menuActive: false,
      languageMenuActive: false,
      getCountInterval: null,
      menu,
      settings
		}
	},
	watch: {
    // '$store.state.webSocket': {
    //   handler(newValue) {
    //     // Note: `newValue` will be equal to `oldValue` here
    //     // on nested mutations as long as the object itself
    //     // hasn't been replaced.
    //     console.log(newValue.readyState)
    //     setTimeout(() => console.log(newValue.readyState), 400)
    //
    //   },
    //   deep: true
    // },
		$route() {
      this.changeTitle()
			this.menuActive = false;
			this.showSearchInput = false;
			// this.$toast.removeAllGroups();

			if (this.$store.state.user?.role) {
        this.checkPagePermission()
      }
		},
    '$i18n.locale'() {
      this.changeTitle()
      this.translatePrimevueComponents()
    },
    'user.language'() {
      this.$i18n.locale = this.user.language ?? settings.defaultLanguage
    },
    '$store.state.firstLayerIsOpened'() {
      const appTopbar = document.getElementById('appTopbar')
      const appTopbarPaddingRight = +parseInt(window.getComputedStyle(appTopbar).paddingRight)
      const scrollWidth = window.innerWidth - document.documentElement.clientWidth

      if (this.$store.state.firstLayerIsOpened) {
        document.documentElement.style.paddingRight = scrollWidth + 'px'
        appTopbar.style.paddingRight = appTopbarPaddingRight + scrollWidth + 'px'
        document.documentElement.style.overflowY = 'hidden'
      } else {
        appTopbar.style.paddingRight = ''
        document.documentElement.style.paddingRight = ''
        document.documentElement.style.overflowY = 'auto'
      }
    },
    // '$store.state.user'() {
    //   this.checkUserRoleBeforeCreatingWebSocket()
    //   // console.log(value)
    //
    // }
	},
  beforeMount() {
    this.checkLogoutTimestampOverdueBeforeMount()
  },
  mounted() {
    if (settings.autoLogoutTimeout) {
      if (this.$store.state.logoutTimestampIsOverdue) return false
      this.setLastActivityTimestamp()
      window.addEventListener('click', this.setLastActivityTimestamp)
      window.addEventListener('contextmenu', this.setLastActivityTimestamp)
      window.addEventListener('onscroll', this.setLastActivityTimestamp);
      window.addEventListener('wheel', this.setLastActivityTimestamp);
      // window.addEventListener('mousemove', this.setLastActivityTimestamp);
      this.setCheckLastActivityInterval()
    }

    this.getUserData()
    this.checkWindowSize()
    if (this.$i18n.locale === settings.defaultLanguage) {
      this.changeTitle()
    }
    this.translatePrimevueComponents()

    window.addEventListener('resize', this.checkWindowSize)
    window.addEventListener('keydown', this.onKeyDown)
  },
  unmounted() {
    window.removeEventListener('resize', this.checkWindowSize)
    window.removeEventListener('keydown', this.onKeyDown)
    if (settings.autoLogoutTimeout) {
      window.removeEventListener('click', this.setLastActivityTimestamp)
      window.removeEventListener('contextmenu', this.setLastActivityTimestamp)
      window.removeEventListener('onscroll', this.setLastActivityTimestamp);
      window.removeEventListener('wheel', this.setLastActivityTimestamp);
      // window.removeEventListener('mousemove', this.setLastActivityTimestamp);
      this.clearCheckLastActivityInterval()
      this.clearLogoutCountdownInterval()
      this.closeLogoutCountdownModal()
    }

    this.$store.commit('changeUserData', {})
    this.$store.commit('updateBranchData', {})
    this.$store.commit('updateUserCashboxes', [])
    this.$store.commit('updateUserEmailAccounts', [])

    this.closeWebSocket()
  },
	methods: {
    checkLogoutTimestampOverdueBeforeMount() {
      const timestampIsOverdue = this.logoutTimestampIsOverdue(true)
      if (timestampIsOverdue) {
        this.$store.commit('toggleLogoutTimestampIsOverdue', true)
        this.logout()
      } else {
        this.$store.commit('toggleLogoutTimestampIsOverdue', false)
      }
    },
    logoutTimestampIsOverdue(countAutoLogoutTimeout = false) {
      const lastActivityTimestamp = localStorage.getItem('lastAction')
      const accessToken = localStorage.getItem('ACCESS_TOKEN')
      if (!lastActivityTimestamp || lastActivityTimestamp === '' || !accessToken) return true

      if (countAutoLogoutTimeout) {
        return ((Date.now() - +lastActivityTimestamp) > (settings.autoLogoutTimeout + this.logoutCountdownTimer * 1000))
      } else {
        return ((Date.now() - +lastActivityTimestamp) > settings.autoLogoutTimeout)
      }

    },
    logout() {
      authService.logout()
    },
    onKeyDown() {
      this.closeOnEscape()
      if (settings.autoLogoutTimeout) {
        this.setLastActivityTimestamp()
      }
    },
    setLastActivityTimestamp() {
      if (this.$store.state.logoutCountdownModal) return false
      localStorage.setItem('lastAction', Date.now());
    },
    setCheckLastActivityInterval() {
      const time = 5000 // 5seconds = 5000 milliseconds
      this.checkLastActivityInterval = setInterval(() => this.checkLastActivity(), time)
    },
    checkLastActivity() {
      const timestampWithLogoutTimeoutIsOverdue = this.logoutTimestampIsOverdue(true)
      const timestampIsOverdue = this.logoutTimestampIsOverdue(false)

      if (timestampWithLogoutTimeoutIsOverdue) {
        this.endSession()
      } else if (timestampIsOverdue) {
        this.sendStartLogoutCountdownWebSocket(this.$store.state.user.id)
        this.startLogoutСountdown()
      }
    },
    startLogoutСountdown() {
      this.logoutCountdownTimer = this.logoutCountdownSeconds
      const appName = settings.appName
      document.title = 'Do you wish to continue?' + ' | ' + appName

      this.clearCheckLastActivityInterval()

      // this.openRequiredStoreLayer()
      this.$store.commit('openRequiredAppLayer')
      this.$store.commit('showLogoutCountdownModal', true)

      this.logoutCountdownInterval = setInterval(() => {
        if (this.logoutCountdownTimer >= 1) {
          const timestampIsOverdue = this.logoutTimestampIsOverdue(false)
          if (!timestampIsOverdue) {
            this.cancelLogoutCountdown()
          }
          this.logoutCountdownTimer--
        } else {
          this.endSession()
        }

      }, 1000)
    },
    cancelLogoutCountdownOnClick() {
      this.cancelLogoutCountdown()
    },
    cancelLogoutCountdown() {
      this.logoutCountdownTimer = this.logoutCountdownSeconds
      this.changeTitle()
      this.clearLogoutCountdownInterval()
      this.closeLogoutCountdownModal()
      this.setCheckLastActivityInterval()
    },
    closeLogoutCountdownModal() {
      // this.closeRequiredStoreLayer()
      this.$store.commit('closeRequiredAppLayer')
      // this.$store.commit('toggleFirstLayer', false)
      this.$store.commit('showLogoutCountdownModal', false)
    },
    endSession() {
      this.closeLogoutCountdownModal()
      this.closeAllAppLayers()
      this.clearLogoutCountdownInterval()
      this.clearCheckLastActivityInterval()
      this.$refs.appTopBar?.logoutOnClick()
      // this.logoutOnClick()
    },
    clearLogoutCountdownInterval() {
      // console.log('Clear Logout Countdown Interval')
      if (!this.logoutCountdownInterval) return false
      clearInterval(this.logoutCountdownInterval)
    },
    clearCheckLastActivityInterval() {
      // console.log('Clear Check Last Activity Interval')
      if (!this.checkLastActivityInterval) return false
      clearInterval(this.checkLastActivityInterval)
    },
    checkUserRoleBeforeCreatingWebSocket() {
      if (this.$store.state.user?.role) {
        if (this.$store.state.user.role === constants.userRoles.superAdmin ||
            this.$store.state.user.role === constants.userRoles.admin ||
            this.$store.state.user.role === constants.userRoles.moderator ||
            this.$store.state.user.role === constants.userRoles.accountant) {
          this.createWebSocket()
        }
      }
    },
    changeTitle() {
      const appName = settings.appName
      if (this.$route.meta.title) {
        document.title = this.$t(this.$route.meta.title) + ' | ' + appName
      } else {
        document.title =  appName
      }
    },
    translatePrimevueComponents() {
      if (this.$i18n) {
        this.$primevue.config.locale.today = this.$t('Today');
        this.$primevue.config.locale.clear = this.$t('Clear');
        this.$primevue.config.locale.emptyFilterMessage = this.$t('No results found');
        this.$primevue.config.locale.emptyMessage = this.$t('No available options');

        this.$primevue.config.locale.firstDayOfWeek = 1;

        this.$primevue.config.locale.dayNamesMin = [this.$t('Su'), this.$t('Mo'), this.$t('Tu'), this.$t('We'), this.$t('Th'), this.$t('Fr'), this.$t('Sa')]
        // this.$primevue.config.locale.monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
        // this.$primevue.config.locale.monthNamesShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      }
    },
    closeOnEscape() {
      // if (event.which !== 27 || this.$store.state.dropdownOverlayVisible || this.$store.state.logoutCountdownModal) return
      if (event.which !== 27 || this.$store.state.logoutCountdownModal) return
      // this.closeRequiredStoreLayer()
      this.$store.commit('closeRequiredAppLayer')
    },
    // closeRequiredStoreLayer() {
      // if (this.$store.state.firstLayerIsOpened &&
      //     !this.$store.state.secondLayerIsOpened &&
      //     !this.$store.state.thirdLayerIsOpened &&
      //     !this.$store.state.fourthLayerIsOpened) {
      //       this.$store.commit('toggleFirstLayer', false)
      // } else if (this.$store.state.firstLayerIsOpened &&
      //     this.$store.state.secondLayerIsOpened &&
      //     !this.$store.state.thirdLayerIsOpened) {
      //       this.$store.commit('toggleSecondLayer', false)
      // } else if (this.$store.state.firstLayerIsOpened &&
      //     this.$store.state.secondLayerIsOpened &&
      //     this.$store.state.thirdLayerIsOpened) {
      //       this.$store.commit('toggleThirdLayer', false)
      // } else if (this.$store.state.firstLayerIsOpened &&
      //     this.$store.state.secondLayerIsOpened &&
      //     this.$store.state.thirdLayerIsOpened &&
      //     this.$store.state.fourthLayerIsOpened) {
      //   this.$store.commit('toggleFourthLayer', false)
      // }
    //   if (this.$store.state.fourthLayerIsOpened) {
    //     this.$store.commit('toggleFourthLayer', false)
    //   } else if (this.$store.state.thirdLayerIsOpened) {
    //     this.$store.commit('toggleThirdLayer', false)
    //   } else if (this.$store.state.secondLayerIsOpened) {
    //     this.$store.commit('toggleSecondLayer', false)
    //   } else if (this.$store.state.firstLayerIsOpened) {
    //     this.$store.commit('toggleFirstLayer', false)
    //   }
    // },
    closeAllAppLayers() {
      this.$store.commit('toggleFourthLayer', false)
      this.$store.commit('toggleThirdLayer', false)
      this.$store.commit('toggleSecondLayer', false)
      this.$store.commit('toggleFirstLayer', false)
      this.$store.commit('toggleDropdownOverlayVisibility', false)
    },
    // openRequiredStoreLayer() {
    //   if (!this.$store.state.firstLayerIsOpened) {
    //     this.$store.commit('toggleFirstLayer', true)
    //   }
      // else if (!this.$store.state.secondLayerIsOpened) {
      //
      // }
      // if (!this.$store.state.firstLayerIsOpened &&
      //     !this.$store.state.secondLayerIsOpened &&
      //     !this.$store.state.thirdLayerIsOpened) {
      //       this.$store.commit('toggleFirstLayer', true)
      // } else if (this.$store.state.firstLayerIsOpened &&
      //     !this.$store.state.secondLayerIsOpened &&
      //     !this.$store.state.thirdLayerIsOpened) {
      //       this.$store.commit('toggleSecondLayer', true)
      // } else if (this.$store.state.firstLayerIsOpened &&
      //     this.$store.state.secondLayerIsOpened &&
      //     !this.$store.state.thirdLayerIsOpened) {
      //       this.$store.commit('toggleThirdLayer', true)
      // }
    // },
    checkPagePermission() {
      const roles = this.$route.meta.roles
      if (roles) {
        if (this.$store.state.user?.role && roles.indexOf(this.$store.state.user.role) < 0) {
          this.$router.push({ name: 'Access' })
        }
      }
    },
    toggleSearchInput() {
      this.showSearchInput = !this.showSearchInput
      this.showSearchClick = !this.showSearchClick

      // console.log(this.showSearchInput)
      // console.log(this.showSearchClick)
    },
    async getUserData() {
      // console.log('get user data in App.vue')
      const { userData, success, err } = await authService.getUserData()
      if (success && userData) {
        this.$store.commit('changeUserData', {
          id: userData.id,
          first_name: userData.first_name,
          middle_name: userData.middle_name,
          last_name: userData.last_name,
          language: userData.language,
          role: userData.role,
          profile_img: userData.profile_img
        })

        if (userData.role === constants.userRoles.superAdmin ||
            userData.role === constants.userRoles.admin ||
            userData.role === constants.userRoles.moderator ||
            userData.role === constants.userRoles.accountant)
        {
          this.createWebSocket()
          if(this.$route.path !== '/offers') {
            this.getNewOffersCount()
          }
        }

        if (userData.language) {
          localStorage.setItem('lang', userData.language)
          if (this.$i18n) {
            this.$i18n.locale = userData.language
          }
          // this.translatePrimevueComponents()
        }

        if (userData.settings) {
          const settings = JSON.parse(userData.settings)
          this.user.settings = settings

          if (this.$store.state.tabletLayout && settings.layoutMode !== 'static') {
            this.actualLayoutMode = 'static'
            this.$store.commit('changeLayoutMode', 'static')
          } else {
            this.actualLayoutMode = settings.layoutMode
            this.$store.commit('changeLayoutMode', settings.layoutMode)
          }

          this.$primevue.config.ripple = settings.ripple
          this.$appState.inputStyle = settings.inputStyle
          this.$primevue.config.inputStyle = settings.inputStyle
        } else {
          this.$store.commit('changeAppSchema', this.user.settings.schema)
          // console.log(this.user.settings.schema)
          // this.actualLayoutMode = this.user.settings.layoutMode
          // thussettings: {
          //   ripple: true,
          //       inputStyle: 'outlined',
          //       layoutMode: 'horizontal',
          //       schema: 'light',
          //       theme: 'blue'
          // }
          // this.
          // this.$store.commit('changeAppSchema', this.shema)
        }

        if (userData.branch) {
          this.$store.commit('updateBranchData', userData.branch)
        }
        if (userData.cashboxes) {
          // console.log(userData.cashboxes)
          // const cashboxes = userData.cashboxes.map(cashbox => cashbox.cashbox)
          this.$store.commit('updateUserCashboxes', userData.cashboxes)
        }
        if (userData.emailAccounts) {
          // console.log(userData.emailAccounts)
          // const emailAccounts = userData.emailAccounts.map(cashbox => cashbox.cashbox)
          this.$store.commit('updateUserEmailAccounts', userData.emailAccounts)
        }
      } else {
        this.showError(err)
      }
      this.checkPagePermission()
    },
    checkWindowSize() {
      if (window.innerWidth <= 1024) {
        // this.actualLayoutMode = 'static'
        this.$store.commit('changeLayoutMode', 'static')

        this.$store.commit('toggleTabletLayout', true)
        if (window.innerWidth <= 640) {
          this.$store.commit('toggleMobileLayout', true)
        } else {
          this.$store.commit('toggleMobileLayout', false)
        }
      } else {

        this.$store.commit('changeLayoutMode', this.user.settings.layoutMode)
        // this.actualLayoutMode = this.user.settings.layoutMode

        this.$store.commit('toggleTabletLayout', false)
        this.$store.commit('toggleMobileLayout', false)
      }
      this.logoIsSmall = window.innerWidth <= 740
    },
		onDocumentClick() {
      // this.setLastActionTimestamp()

			if (!this.topbarItemClick) {
				this.activeTopbarItem = null;
				this.topbarMenuActive = false;
			}

      if (!this.languageMenuButtonClick) {
        this.languageMenuActive = false
      }

      // if (this.showSearchClick) {
      //   this.showSearchInput = false;
      // }

			if (!this.menuClick) {
				if (this.isHorizontal() || this.isSlim()) {
					this.menuActive = false;
				}

        EventBus.emit('reset-active-index');
				this.hideOverlayMenu();
			}


      this.languageMenuButtonClick = false
			this.topbarItemClick = false;
			this.menuClick = false;
		},
		onMenuClick() {
			this.menuClick = true;
		},
		onMenuButtonClick(event) {
			this.menuClick = true;
			this.topbarMenuActive = false;

			if (this.user.settings.layoutMode === 'overlay' && !this.isMobile()) {
				this.overlayMenuActive = !this.overlayMenuActive;
			}
			else {
				if (this.isDesktop())
					this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
				else
					this.staticMenuMobileActive = !this.staticMenuMobileActive;
			}

			event.preventDefault();
		},
		onTopbarMenuButtonClick(event) {
			this.topbarItemClick = true;
			this.topbarMenuActive = !this.topbarMenuActive;
			this.hideOverlayMenu();
			event.preventDefault();
		},
		onTopbarItemClick(event) {
			this.topbarItemClick = true;

			if (this.activeTopbarItem === event.item)
				this.activeTopbarItem = null;
			else
				this.activeTopbarItem = event.item;

			event.originalEvent.preventDefault();
		},
		isDesktop() {
			return window.innerWidth > 1024;
		},
		isMobile() {
			return window.innerWidth <= 1040;
		},
		isHorizontal() {
      return this.user.settings.layoutMode === 'horizontal';
		},
		isSlim() {
			return this.user.settings.layoutMode === 'slim';
    },
		hideOverlayMenu() {
			this.overlayMenuActive = false;
			this.staticMenuMobileActive = false;
		},
		onMenuItemClick(event) {
			if (!event.item.items) {
				EventBus.emit('reset-active-index');
				this.hideOverlayMenu();
			}
			if (!event.item.items && (this.isHorizontal() || this.isSlim())) {
				this.menuActive = false;
			}
		},
		onRootMenuItemClick() {
			this.menuActive = !this.menuActive;
		},
    isMenuVisible() {
      if (this.isDesktop()) {
          if (this.user.settings.layoutMode === 'static')
              return !this.staticMenuDesktopInactive;
          else if (this.user.settings.layoutMode === 'overlay')
              return this.overlayMenuActive;
          else
              return true;
      }
      else {
          return true;
      }
    },
    toggleConfigurator() {
      this.configuratorActive = !this.configuratorActive;
    },
    onLanguageMenuButtonClick() {
      this.languageMenuActive = !this.languageMenuActive
      this.languageMenuButtonClick = true

      // event.preventDefault();
    },
    hideConfigurator() {
      this.configuratorActive = false;
    },
    onLayoutChange(layoutMode) {
      this.user.settings.layoutMode = layoutMode;
      this.originalLayoutMode = layoutMode;
      this.changeUserSettings()

      // this.layoutModeOnResize = layoutMode;
    },
    changeInputStyle(value) {
      this.$appState.inputStyle = value;
      this.$primevue.config.inputStyle = value;
      this.user.settings.inputStyle = value;
      this.changeUserSettings()
    },
    changeFullTheme(data, saveUserSettings = true) {
      this.user.settings.theme = data.theme
      this.user.settings.schema = data.schema
      // if (this.changedLayoutToStatic) {
      //   this.user.settings.layoutMode = this.originalLayoutMode
      // }
      if (saveUserSettings) {
        this.changeUserSettings()
      }
    },
    changeRippleSettings() {
      this.user.settings.ripple = this.$primevue.config.ripple
      this.changeUserSettings()
    },
    async changeUserSettings() {
      try {
        await httpClient.post(
            'user/change-settings',
            { settings: JSON.stringify(this.user.settings)})
      } catch(err) {
        this.showError(err)
      }
    }
	},
	computed: {
		containerClass() {
			return ['layout-wrapper', {
				'layout-horizontal': this.user.settings.layoutMode === 'horizontal',
				'layout-overlay': this.user.settings.layoutMode === 'overlay',
				'layout-static': this.user.settings.layoutMode === 'static',
				'layout-slim': this.user.settings.layoutMode === 'slim',
				'layout-static-inactive': this.staticMenuDesktopInactive,
				'layout-mobile-active': this.staticMenuMobileActive,
				'layout-overlay-active': this.overlayMenuActive,
        'p-input-filled': this.$appState.inputStyle === 'filled' || this.$primevue.config.inputStyle === 'filled',
        'p-ripple-disabled': this.$primevue.config.ripple === false
			}];
    },
    // computedMenu() {
    //   let menu = []
    //   const userRole = this.$store.state.user.role
    //   if (userRole) {
    //
    //     [...this.menu].forEach(item => {
    //       if (item.roles && item.roles.indexOf(+userRole) > -1) {
    //         // let items = []
    //
    //         // item.items.forEach(i => {
    //         //   if (i.roles.indexOf(+userRole) > -1) {
    //         //     items.push(i)
    //         //   }
    //         // })
    //
    //         // const submenuItems = [...this.menu].find(m => m.index === item.index)?.items
    //         // const submenuItems = item.items.filter(i => i.roles.indexOf(userRole) >= 0)
    //         // const submenuItems = item.items.filter(i => i.roles.indexOf(userRole) >= 0)
    //
    //         // console.log((submenuItems.filter(mi => mi.roles.indexOf(+userRole) > -1).length))
    //
    //         item.items = [...item.items].filter(mi => mi.roles.indexOf(+userRole) > -1)
    //         // console.log(item.items)
    //
    //         // items = submenuItems.filter(mi => mi.roles.indexOf(+userRole) > -1)
    //         // item.items = items
    //
    //         menu.push(item)
    //       }
    //     })
    //   }
    //   return menu
    // },
    computedMenu() {
      let activeMenu = []
      const userRole = this.$store.state.user?.role
      if (userRole) {
        this.menu.forEach(item => {
          if (item.roles && item.roles.indexOf(+userRole) > -1) {
            item.items = item.subMenuItems.filter(subMenuItem => subMenuItem.roles.indexOf(+userRole) > -1)
            activeMenu.push(item)
          }
        })
      }
      return activeMenu
    },
    // actualLayoutMode() {
    //   // if (window.innerWidth <= 1024 && this.user.settings.layoutMode === 'horizontal') {
    //   //   console.log('aaa')
    //   //   return 'static'
    //   // } else {
    //   //   console.log(this.user.settings.layoutMode)
    //   //   return this.user.settings.layoutMode
    //   // }
    //   if (window.innerWidth <= 1024) {
    //     return 'static'
    //   } else {
    //     return this.user.settings.layoutMode
    //   }
    // }
	},
}
</script>

<style lang="scss">
@import 'App.scss';

</style>
